import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import TopBar from "./components/topbar/topbar";

// Views
import Home from "./views/home/home";
import Blogs from "./views/blogs/blogs";
import WhatIsAi from "./views/what-is-ai/what-is-ai";
import Testimonial from "./views/testimonial/testimonial";
import OurSolution from "./views/our-solution/our-solution";
import Team from "./views/team/team";
import FAQs from "./views/FAQs/FAQs";
import Adjusters from "./views/Industries/adjusters";
import Attorneys from "./views/Industries/attorneys";
import Contractors from "./views/Industries/contractors";
import Insurers from "./views/Industries/insurers-tpa";
// Added new views
import TermsOfUse from "./views/terms-of-use/terms-of-use";
import PrivacyPolicy from "./views/privacy-policy/privacy-policy";
import LoginSignup from "./views/login-signup/login-signup";
import Pricing from "./views/pricing/pricing";
import LogIn from "./views/login/login";
import ContactUs from "./views/contact-us/contact-us";
import TestPayments from "./views/test-payments/test-payments";
import FreeTrial from "./views/login-signup/free-trial";
import BlogDetail from "./views/blogs/blog-detail";
import ArticlePage from "./views/blogs/article-page";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { NonceContext } from "./NonceContext";

// adding vercel analytics
import { Analytics } from "@vercel/analytics/react";

// adding react-helmet-async
import { Helmet, HelmetProvider } from "react-helmet-async";

// creating nonce for CSP
function generateNonce() {
    return btoa(crypto.getRandomValues(new Uint8Array(16)).join(""));
  }
  
  const nonce = generateNonce();

function App() {
    const [loader, setloader] = useState(true);
    const [isMobile, setIsMobile] = useState(false);
    const location = useLocation();
    const nonce = useContext(NonceContext);

    const excludePaths = ["/terms-of-use", "/privacy-policy", "/pricing", "/login", "/login-signup", "/contact-us", "/our-solution", "/adjusters", "/attorneys", "/contractors", "/insurers-tpa"];
    const hideFooterUpperShape = ["/adjusters", "/attorneys", "/contractors", "/insurers-tpa"];
    const showFooterUpperShape = !hideFooterUpperShape.includes(location.pathname);

    useEffect(() => {
        setloader(true);

        // Check if it's a mobile device
        const checkMobile = () => {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            // Basic check for mobile devices
            if (/android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent.toLowerCase())) {
                setIsMobile(true);
            }
        };

        checkMobile();
        const timeoutDuration = isMobile ? 2000 : 2000;
        setTimeout(() => {
            setloader(false);
        }, timeoutDuration);
    }, []);

    // Dynamically load the HubSpot script on the Our Solution page
    useEffect(() => {
        if (location.pathname === "/our-solution") {
            const script = document.createElement("script");
            script.src = "//js.hs-scripts.com/47142898.js";
            script.async = true;
            script.defer = true;
            script.id = "hs-script-loader";
            document.body.appendChild(script);

            return () => {
                const hsScript = document.getElementById("hs-script-loader");
                if (hsScript) {
                    document.body.removeChild(hsScript);
                }
            };
        }
    }, [location.pathname]);

    // Show the loader if the loader state is true
    if (loader && !excludePaths.includes(location.pathname)) {
        return (
            <div className="w-full h-screen flex items-center justify-center bg-[#01030c]">
                <img src="/assets/images/logo.png" alt="color-favi" className="animate-pulse" />
            </div>
        );
    }

    return (
        <HelmetProvider>
            <div className="App">
                <Helmet>
                <meta
                    httpEquiv="Content-Security-Policy"
                    content={`default-src 'self'; script-src 'self' ${nonce ? `'nonce-${nonce}'` : ""} js.hsforms.net js.hs-scripts.com analytics.vercel.com js.stripe.com; style-src 'self' ${nonce ? `'nonce-${nonce}'` : ""} fonts.googleapis.com; img-src 'self' data: aws.com s3.amazonaws.com analytics.vercel.com www.facebook.com; font-src 'self' fonts.gstatic.com; connect-src 'self' js.hsforms.net analytics.vercel.com api.deltaclaims.ai; frame-src 'self' js.stripe.com js.hs-scripts.com; upgrade-insecure-requests;`}
                />
                    <title>DeltaClaims.AI | AI-Driven Claims Adjustment and Management Services</title>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <meta
                        name="description"
                        content="DeltaClaims.AI offers cloud-native, AI-powered solutions for insurance claims adjustment and management."
                    />
                    <meta name="keywords" content="AI claims adjustment, insurance claims automation, cloud-native claims processing" />
                    <meta name="robots" content="index,follow" />
                    <meta name="theme-color" content="#00193d" />
                    <meta property="og:title" content="DeltaClaims.AI - We Make Dispute Resolution Seamless." />
                    <meta property="og:description" content="AI-powered claims adjustment solutions for faster, more efficient claims management." />
                    <meta property="og:url" content="https://www.deltaclaims.ai" />
                    <meta property="og:image" content="https://www.deltaclaims.ai/assets/images/social-media-logo.png" />
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content="DeltaClaims.AI" />
                    <meta property="og:locale" content="en_US" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="twitter:domain" content="deltaclaims.ai" />
                    <meta property="twitter:url" content="https://www.deltaclaims.ai" />
                    <meta name="twitter:title" content="DeltaClaims.AI - We Make Dispute Resolution Seamless." />
                    <meta name="twitter:description" content="AI-powered claims adjustment solutions for faster, more efficient claims management." />
                    <meta name="twitter:image" content="https://www.deltaclaims.ai/assets/images/social-media-logo.png" />
                </Helmet>
                <div className="lg:absolute w-full top-0 left-0 right-0 mx-auto xl:z-[400]">
                    {/*<TopBar />*/}
                    <Header />
                    <Analytics />
                </div>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/our-solution" element={<OurSolution key={location.pathname} />} />
                    <Route path="/blog" element={<Blogs />} />
                    <Route path="/team" element={<Team />} />
                    <Route path="/what-is-ai" element={<WhatIsAi />} />
                    <Route path="/FAQs" element={<FAQs />} />
                    <Route path="/testimonials" element={<Testimonial />} />
                    <Route path="/adjusters" element={<Adjusters />} />
                    <Route path="/attorneys" element={<Attorneys />} />
                    <Route path="/contractors" element={<Contractors />} />
                    <Route path="/insurers-tpa" element={<Insurers />} />
                    
                    {/* Added new routes */}
                    <Route path="/terms-of-use" element={<TermsOfUse />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/login-signup" element={<LoginSignup />} />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/login" element={<LogIn />} />
                    <Route path="/contact-us" element={<ContactUs />} />
                    <Route path="/test-payments" element={<TestPayments />} />
                    <Route path="/free-trial" element={<FreeTrial />} />
                    <Route path="/blog-detail" element={<BlogDetail />} />
                    <Route path="/article" element={<ArticlePage />} />
                </Routes>
                <Footer showFooterUpperShape={showFooterUpperShape}/>
            </div>
        </HelmetProvider>
    );
}

export default App;
