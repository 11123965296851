import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { sendToVercelAnalytics } from "./vitals";
import { BrowserRouter } from 'react-router-dom';
import { NonceProvider } from "./NonceContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <NonceProvider>
    <App />
  </NonceProvider>
);

const container = document.getElementById('root');

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals(sendToVercelAnalytics);
