import React from "react";

const Newsletter = () => {
    return (
        <div
            style={{
                "background": "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                "background": "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                "background": "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                "background": "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
            }}
            className="bg-gradient-to-r from-[#59bb66] to-[#0195d8] p-6 flex flex-wrap lg:flex-nowrap gap-4 lg:gap-x-[60px] items-center rounded-3xl">
            <img src="/assets/images/newsletter.png" className="w-full lg:w-6/12 -mt-12" loading="lazy" decoding="async"/>
            <div className="w-full lg:w-5/12 text-white text-left mx-auto">
                <div className="flex items-center gap-2">
                    <img src="/assets/images/favi.png" />
                    <p className="font-['Poppins'] text-base lg:text-[22px] font-medium uppercase">newsletter</p>
                </div>
                <h1 className="text-2xl lg:text-[40px] font-['Montserrat'] mt-4">Subscribe For <span className="font-bold">Updates</span></h1>
                <p className="text-base font-['Montserrat'] my-4">Receive news about upcoming events, product features, and advice from industry experts</p>
                <div className="relative w-full h-[60px] rounded-full bg-white mt-10">
                    <input className="h-full w-full pr-2 pt-2 pb-2 rounded-full outline-none pl-2 lg:pl-10 text-base font-['Montserrat'] text-black" type="email" id="email" placeholder="Enter your email" />
                    <button style={{
                        "background": "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                        "background": "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                        "background": "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                        "background": "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    }} className="bg-gradient-to-r from-[#59bb66] to-[#0195d8] p-2 text-white absolute right-2 top-2 w-[80px] h-[45px] rounded-full flex items-center justify-center">
                        <img src="/assets/images/send-icon.png" />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Newsletter;