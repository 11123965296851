import React, { useState, useEffect } from 'react';
import Button from '../../components/ui/button';

// Top Bar Placeholder Component
const TopBarPlaceholder = () => {
  const topBarStyle = {
    height: '60px',
    backgroundColor: '#001235',
    position: 'relative',
    zIndex: 10,
    marginTop: '120px',
  };

  return (
    <div style={topBarStyle}>
      {/* Top bar content can be added here */}
    </div>
  );
};

// Main Header Component
const MainHeader = ({ title }) => {
  const headerStyle = {
    background: 'linear-gradient(135deg, #001235, #33abd0, #001235)',
    padding: '60px 20px',
    textAlign: 'center',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
    position: 'relative',
  };

  const headerContentStyle = {
    maxWidth: '1200px',
    margin: '0 auto',
  };

  const headerTitleStyle = {
    fontSize: '3rem',
    fontWeight: 550,
    color: '#fff',
    textTransform: 'uppercase',
    letterSpacing: '2px',
    fontFamily: "'Montserrat', sans-serif",
  };

  const responsiveHeaderTitleStyle = window.innerWidth <= 768 ? { fontSize: '2rem' } : {};

  return (
    <div style={headerStyle}>
      <div style={headerContentStyle}>
        <h1 style={{ ...headerTitleStyle, ...responsiveHeaderTitleStyle }}>{title}</h1>
      </div>
      {/* <img
        src="/assets/images/what_is_ai/bottom-shade-what.png"
        style={{
          position: 'absolute',
          bottom: '-400px',
          width: '100%',
          left: 0,
          right: 0,
          margin: '0 auto'
        }}
        alt="pixel"
      /> */}
    </div>
  );
};

// Intro Paragraph Component
const IntroParagraph = ({ text }) => {
  const introSectionStyle = {
    width: '100%',
    maxWidth: '1400px',
    backgroundColor: '#001235',
    color: '#001235',
    padding: '15px',
    borderRadius: '12px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    marginTop: '40px',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  };

  const paragraphStyle = {
    fontSize: '2.5rem',
    fontWeight: 400,
    fontFamily: "'Montserrat', sans-serif",
    textAlign: 'center',
    color: '#fff',
  };

  const responsiveIntroSectionStyle = window.innerWidth <= 768 ? { padding: '20px' } : {};

  return (
    <div style={{ ...introSectionStyle, ...responsiveIntroSectionStyle }}>
      <p style={paragraphStyle}>{text}</p>
    </div>
  );
};

// Intro Subtitle Component
const IntroSubtitle = ({ text }) => {
  const introSubtitleStyle = {
    width: '100%',
    maxWidth: '1400px',
    backgroundColor: '#001235',
    color: '#001235',
    padding: '15px',
    borderRadius: '12px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  };

  const textStyle = {
    fontSize: '1.6rem',
    fontWeight: 200,
    fontFamily: "'Montserrat', sans-serif",
    textAlign: 'center',
    color: '#fff',
  };

  const responsiveIntroSectionStyle = window.innerWidth <= 768 ? { padding: '20px' } : {};

  return (
    <div style={{ ...introSubtitleStyle, ...responsiveIntroSectionStyle }}>
      <p style={textStyle}>{text}</p>
    </div>
  );
};

// CTA Section Component
const CTASection = ({ subheading }) => {
  const ctaSectionStyle = {
    textAlign: 'center',
  };

  const ctaSubheadingStyle = {
    fontSize: '2rem',
    fontWeight: 700,
    color: '#5DBC64',
    marginTop: '20px',
    textTransform: 'uppercase',
    fontFamily: "'Montserrat', sans-serif",
  };

  const responsiveCtaSubheadingStyle = window.innerWidth <= 768 ? { fontSize: '1.5rem' } : {};

  return (
    <div style={ctaSectionStyle}>
      <h2 style={{ ...ctaSubheadingStyle, ...responsiveCtaSubheadingStyle }}>{subheading}</h2>
    </div>
  );
};

// Feature Box Component
const FeatureBox = ({ icon, title, description, video, linkText }) => {
  const [isHovered, setIsHovered] = useState(false);

  const boxStyle = {
    backgroundColor: 'bg-gradient-to-r from-[#59bb66] to-[#0195d8]',
    borderRadius: '12px',
    marginTop: '20px',
    padding: '2rem',
    justifyContent: 'center',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    transform: isHovered ? 'translateY(-10px)' : 'translateY(0)',
    boxShadow: isHovered 
      ? '0 15px 30px rgba(0, 0, 0, 0.2)' 
      : '0 4px 6px rgba(0, 0, 0, 0.1)',
  };

  const iconStyle = {
    width: '48px',
    height: '48px',
    backgroundColor: '#001235',
    borderRadius: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // marginBottom: 'rem',
  };

  const titleStyle = {
    color: '#FFFFFF',
    fontSize: '2.50rem',
    marginBottom: '1rem',
    fontWeight: '400',
  };

  const descriptionStyle = {
    color: 'rgba(255, 255, 255, 0.7)',
    marginBottom: '1.5rem',
    fontSize: '1.25rem',
  };

  const videoStyle = {
    width: '100%',
    height: 'auto',
    borderRadius: '12px',
    marginBottom: '1.5rem',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  }

  const linkStyle = {
    display: 'inline-block',
    color: '#5DBC64',
    textDecoration: 'none',
    fontWeight: '500',
    transition: 'color 0.3s ease',
  };

  return (
    <div 
      style={boxStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div style={iconStyle}>
        {icon}
      </div>
      <h3 style={titleStyle}>{title}</h3>
      <p style={descriptionStyle}>{description}</p>
      <a href="#" style={linkStyle}>{linkText}</a>
      <div style={videoStyle}>
        {video}
    </div>
  </div>
  );
};

// Feature Boxes Section Component
const FeatureBoxesSection = () => {
  const [columns, setColumns] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      setColumns(1); // Always stack rows on top of each other
    };

    // Initial call
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
    gap: '1.5rem',
    marginTop: '2rem',
  };

  return (
    <div style={{
      width: '100%',
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '2rem 1rem',
    }}>
      <div style={gridStyle}>
        <FeatureBox 
          icon={<img width="24" height="24" viewBox="0 0 24 24" fill="#FFFFFF" src="/assets/images/home/color-favi.png" />}
          title="DeltaMate Report"
          description="Perform swift, line-by-line comparisons of any two estimates. Filter by RCV, ACV, depreciation, overhead, and profit. Pinpoint claim variances to efficiently reduce risk, prevent overpayment, and accelerate decisions."
          video={<video muted controls src="/assets/images/our-solution/DM-Demo.mp4" type="video/mp4" preload="metadata" poster="/assets/images/home/DM-1.png"/>}
          linkText="" 
        />
        <FeatureBox 
          icon={<img width="24" height="24" viewBox="0 0 24 24"  src="/assets/images/our-solution/policyicon.svg"/>}
          title="Policy Analysis"
          description="Analyze policy documents in real time, extracting relevant coverage amounts, limits, and exclusions. Reduce interpretation time and enforce standards of handling policy language."
          video={<video muted controls src="/assets/images/our-solution/PolicyDemo.mp4" type="video/mp4" preload="metadata" poster="/assets/images/home/policy-page.png"/>}
          linkText=""
        />
        <FeatureBox 
          icon={<img width="24" height="24" viewBox="0 0 24 24" src="/assets/images/our-solution/8icon.svg" />}
          title="Auto Document Classification"
          description="Save time on file management with AI-powered document sorting. Documents are automatically categorized (as estimates, photos, reports, images, etc.) and tagged as such for easy retrieval and management." 
          video={<video muted controls src="/assets/images/our-solution/DocumentDemo.mp4" type="video/mp4" preload="metadata" poster="/assets/images/home/docs3.png"/>}
          linkText=""
        />
      </div>
    </div>
  );
};

// Attorneys Page Component
const Insurers = () => {
  const appStyle = {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#001235',
    color: '#fff',
    lineHeight: 1.6,
    fontFamily: "'Montserrat', sans-serif",
    position: 'relative',
  };

  const contentSectionStyle = {
    width: '100%',
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '40px 20px',
    flex: 1,
  };

  return (
    <div style={appStyle}>
      <TopBarPlaceholder />
      <MainHeader title="Modern analytics for the Modern Agency"/>
      <div style={contentSectionStyle}>
        <IntroParagraph text="Support your claim handlers with speed, consistency, and precision." />   
        <IntroSubtitle text="Enhance visibility as you reduce turnaround." />          
        {/* New Feature Boxes Section */}
        <FeatureBoxesSection />
        
        <CTASection subheading="" />
      </div>
      <div className="w-full pb-[100px] flex justify-center z-50">
                        <Button
                            type={"button"}
                            text={"get access"}
                            fill={true}
                            icon={true}
                            onClick={() => (window.location.href = "/login-signup")}
                        />
      </div>
      <img
        src="/assets/images/what_is_ai/bottom-what.png"
        style={{
          position: 'absolute',
          bottom: '-400px',
          width: '100%',
          left: 0,
          right: 0,
          margin: '0 auto',
          paddingBottom: '300px'
        }}
        alt="pixel"
      />
    </div>
  );
};

export default Insurers;