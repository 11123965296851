import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import ContextMenu from "./ContextMenu";
import IndustryMenu from "./IndustryMenu";

const NavigationLinks = ({ toggleMenu, isMobile }) => {
    const loc = useLocation();

    const activeUnderline = (
        <hr
            style={{
                background: "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)"
            }}
            className="w-full bg-gradient-to-r from-[#59bb66] to-[#0195d8] h-px mt-2 border-0"
        />
    );

    return (
        <ul className="flex flex-col font-medium xl:flex-row gap-6 xl:gap-[60px] text-left p-6 xl:p-0">
            {isMobile && (
                <button
                    onClick={toggleMenu}
                    type="button"
                    style={{
                        background: "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)"
                    }}
                    className="inline-flex items-center p-2 ml-1 text-sm text-white rounded-lg xl:hidden 
            focus:outline-none bg-gradient-to-r from-[#59bb66] to-[#0195d8] h-fit focus:ring-2 focus:ring-[#0195d8]
            w-fit ml-auto">
                    <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        />
                    </svg>
                </button>
            )}

            <li className={isMobile ? "" : "relative group"}>
                <IndustryMenu isMobile={isMobile} />
            </li>

            <li onClick={isMobile ? toggleMenu : undefined}>
                <NavLink
                    to="/our-solution"
                    className={({ isActive }) =>
                        `block py-2 text-white relative overflow-hidden rounded-lg transition-all duration-300
            hover:bg-white/10 hover:backdrop-blur-sm px-4 ${isActive ? "bg-white/5 backdrop-blur-sm" : ""}`
                    }>
                    Features
                    {loc.pathname === "/our-solution" && activeUnderline}
                </NavLink>
            </li>

            <li className={isMobile ? "" : "relative group"}>
                <ContextMenu isMobile={isMobile} />
            </li>

            <li onClick={isMobile ? toggleMenu : undefined}>
                <NavLink
                    to="/FAQs"
                    className={({ isActive }) =>
                        `block py-2 text-white relative overflow-hidden rounded-lg transition-all duration-300
            hover:bg-white/10 hover:backdrop-blur-sm px-4 ${isActive ? "bg-white/5 backdrop-blur-sm" : ""}`
                    }>
                    FAQs
                    {loc.pathname === "/FAQs" && activeUnderline}
                </NavLink>
            </li>
        </ul>
    );
};

export default NavigationLinks;
