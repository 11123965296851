import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "./slick-theme.css";
import { useEffect } from "react";
import ReactMarkdown from "react-markdown";

const ArticlePage = () => {
    const location = useLocation();
    const { blog } = location.state;
    const [showCopiedMessage, setShowCopiedMessage] = useState(false);

    const handleShare = () => {
        const currentUrl = window.location.href;
        navigator.clipboard.writeText(currentUrl).then(() => {
            setShowCopiedMessage(true);
            setTimeout(() => setShowCopiedMessage(false), 2000);
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000
    };

    return (
        <div className="w-full bg-white min-h-screen">
            {/* Hero Section */}
            <div className="w-full h-[100vh] relative bg-gradient-to-r from-[#0195d8] to-[#59bb66] overflow-hidden">
                <div className="absolute inset-0 bg-black opacity-40"></div>
                <img src={blog.images[0]} alt="hero" className="w-full h-full object-cover" />
                <div className="absolute inset-0 flex items-center justify-center">
                    <div className="max-w-4xl mx-auto px-4 text-center">
                        <h1 className="text-4xl lg:text-6xl font-['Montserrat'] font-bold text-white mb-2">{blog.title}</h1>
                        <p className="text-white text-xl opacity-90">April 2025</p>
                    </div>
                </div>
            </div>

            {/* Content Section */}
            <div className="max-w-4xl mx-auto px-4 py-12">
                {/* Image Gallery */}
                {blog.images.length > 1 && (
                    <div className="mb-12">
                        <Slider {...settings}>
                            {blog.images.map((image, index) => (
                                <div key={index} className="px-2">
                                    <img
                                        src={image}
                                        alt={`slide-${index}`}
                                        className="w-full rounded-lg shadow-lg"
                                        style={{ height: "400px", objectFit: "cover" }}
                                    />
                                </div>
                            ))}
                        </Slider>
                    </div>
                )}

                {/* Article Content */}
                <article className="prose prose-lg max-w-none">
  <div className="font-['Montserrat'] text-lg leading-relaxed space-y-6 text-gray-800">
    {blog.content
      .split('. ')
      .reduce((acc, sentence, index, array) => {
        if (index % 5 === 0) {
          acc.push([]);
        }
        acc[acc.length - 1].push(sentence + (index < array.length - 1 ? '.' : ''));
        return acc;
      }, [])
      .map((paragraph, index) => (
        <p key={index} className="text-left first:indent-4">
          {paragraph.join(' ')}</p>
      ))}
  </div>
</article>

                {/* Share Section */}
                <div className="mt-12 pt-6 border-t border-gray-200">
                    <h3 className="text-xl font-['Montserrat'] font-semibold mb-4">Share this article</h3>
                    <div className="flex gap-4 items-center">
                        <button onClick={handleShare} className="px-6 py-2 bg-[#59bb66] text-white rounded-full hover:bg-opacity-90 transition">
                            Share
                        </button>
                        {showCopiedMessage && <span className="text-[#59bb66] font-medium animate-fade-in-out">Link copied to clipboard!</span>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ArticlePage;
