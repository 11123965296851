import React, { useState } from "react";
import Button from "../ui/button";
import { NavLink, useLocation } from "react-router-dom";
import Modal from "../../Modal";
import NavigationLinks from "./NavigationLinks";

const Header = () => {
    const loc = useLocation();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleMenu = () => {
        const el = document.getElementById("mobile-menu");
        if (el.classList.contains("hidden")) {
            el.classList.remove("hidden");
        } else {
            el.classList.add("hidden");
        }
    };

    const handleContactUsClick = () => {
        window.scrollTo(0, 0);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <nav className="px-4 py-[30px] w-full bg-[#001235] xl:bg-transparent">
            <div className="flex flex-wrap xl:flex-nowrap items-center justify-between items-center mx-auto max-w-[1800px] w-full">
                <div className="w-full justify-center xl:justify-start flex items-center flex-wrap xl:flex-nowrap 2xl:w-8/12 gap-4 xl:gap-4 2xl:gap-x-[80px] logo-main relative z-[200]">
                    <NavLink to={"/"}>
                        <img src="/assets/images/LogoUpgrade.svg" className="block mx-auto" alt="Logo" />
                    </NavLink>
                    <button
                        type="button"
                        onClick={toggleMenu}
                        style={{
                            background: "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)"
                        }}
                        className="inline-flex items-center p-2 ml-1 text-sm text-white rounded-lg xl:hidden 
                        focus:outline-none bg-gradient-to-r from-[#59bb66] to-[#0195d8] h-fit focus:ring-2 focus:ring-[#0195d8]
                        absolute top-0 right-2">
                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fillRule="evenodd"
                                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </button>

                    {/* Mobile navigation menu - hidden on desktop */}
                    <div
                        className="fixed hidden top-0 left-0 h-full z-[150] bg-[#001235]/95
                        backdrop-blur-md justify-between items-center w-full text-base font-['Poppins']"
                        id="mobile-menu">
                        <NavigationLinks toggleMenu={toggleMenu} isMobile={true} />

                        <li className="list-none text-center lg:text-left">
                            <NavLink to="/login-signup" className="block py-2 text-white" onClick={toggleMenu}>
                                <Button type={"button"} text={"Log In / Sign Up"} />
                            </NavLink>
                        </li>
                        <li className="list-none">
                            <Button
                                type={"button"}
                                text={"Contact Us"}
                                fill={true}
                                onClick={() => {
                                    toggleMenu();
                                    window.open("http://47142898.hs-sites.com/en/deltaclaims.ai-contact-us", "_blank");
                                }}
                            />
                        </li>
                    </div>

                    {/* Desktop navigation - visible only on xl screens and up */}
                    <div className="hidden xl:flex items-center justify-between w-full xl:w-auto">
                        <NavigationLinks toggleMenu={toggleMenu} isMobile={false} />
                    </div>
                </div>

                <div className="hidden xl:flex items-center justify-end flex-wrap flex-col 2xl:flex-row xl:flex-nowrap gap-4 items-center w-full xl:w-[230px] 2xl:w-4/12 button-main">
                    <NavLink to="/login-signup">
                        <Button type={"button"} text={"Log In / Sign Up"} />
                    </NavLink>
                    <Button
                        type={"button"}
                        text={"Contact Us"}
                        fill={true}
                        onClick={() => window.open("http://47142898.hs-sites.com/en/deltaclaims.ai-contact-us", "_blank")}
                    />
                </div>
            </div>

            {isModalOpen && <Modal show={isModalOpen} onClose={closeModal} />}
        </nav>
    );
};

export default Header;
