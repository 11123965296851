import { createContext, useEffect, useState } from "react";

export const NonceContext = createContext("");

export function NonceProvider({ children }) {
  const [nonce, setNonce] = useState("");

  useEffect(() => {
    setNonce(btoa(crypto.getRandomValues(new Uint8Array(16)).join("")));
  }, []);

  return (
    <NonceContext.Provider value={nonce}>
      {children}
    </NonceContext.Provider>
  );
}
