import Button from "../../components/ui/button";
import GradientText from "../../components/ui/gradient-text";
import React from "react";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "./slick-theme.css"; // we'll create this custom file

const Blogs = () => {
  const navigate = useNavigate();
  const blogs = [
    {
      images: ["/assets/images/blog/blog2.png"],
      title: "Blog Title 1",
      content: "Content of Blog 1",
    },
    {
      images: ["/assets/images/blog/blog3.png"],
      title: "Blog Title 2",
      content: "Content of Blog 2",
    },
    {
      images: ["/assets/images/blog/blog4.png"],
      title: "Blog Title 3",
      content: "Content of Blog 3",
    },
  ];

  const handleReadMore = (blog) => {
    navigate("/article", { state: { blog } });
  };

  const conferences = [
    {
      imgSrc: "/assets/images/our-solution/plrb-conf.jpg",
      title: "2025 Claims Conference",
      description:
        "The Property & Liability Resource Bureau (PLRB) at the premier educational conference and expo in the industry, hosted next year in Indianapolis, IN. With approximately 100 educational sessions covering a broad range of adjusting challenges including property, commercial time element losses, casualty claims, large property loss, claims management, special investigation/fraud, subrogation, technology, and underwriting and claims, there's something for everyone.",
      location: "Indiana Convention Center | Indianapolis, ID",
      info: {
        date: "February 19-21, 2025",
        website: "https://web.cvent.com/event/aa96efc9-539f-4558-bb0b-c31895d0abbf/summary",
        demos: "All Day",
      },
    },
    {
      imgSrc: "/assets/images/our-solution/ire-expo.png",
      title: "International Roofing Expo 2025",
      description:
        "The International Roofing Expo (IRE) is the largest roofing and exteriors event in North America. With more than 65 years of success, IRE has become the industry's annual hub for products, equipment, hands-on education and networking. We have helped thousands of roofing contractors, expand their network, improve their skills, and keep their business thriving.",
      location: "Henry B. González Convention Center | San Antonio, TX",
      info: {
        date: "February 19-21, 2025",
        website: "https://www.theroofingexpo.com",
        demos: "All Day",
      },
    },
    {
      imgSrc: "/assets/images/our-solution/src-logo.png",
      title: "SRC Summit 2025",
      description:
        "The Storm Restoration Contractor Summit is for contractors who handle storm damage claims. It was started in 2013 by April Hall. The SRC Summit is geared toward best business practices in the insurance restoration industry. You’ll hear from expert speakers, breakout sessions and have the chance to meet the vendors.",
      location: "The Irving Texas Convention Center, TX",
      info: {
        date: "February 4-6, 2025",
        website: "https://www.srcsummit.com",
        demos: "All Day",
      },
    },
    {
      imgSrc: "/assets/images/our-solution/windstorm-logo.png",
      title: "WINDSTORM 2025",
      description:
        'Windstorm Conference brings together leaders, experts, and innovators in the field of windstorm mitigation and response. The event aims to share the latest advancements, best practices, and strategies for dealing with windstorm-related challenges. Attend and gain valuable insights to better prepare and protect your communities." Windstorm Insurance Network',
      location: "Hyatt Regency Dallas, Dallas, TX",
      info: {
        date: "January 26 - 29, 2025",
        website: "https://windnetwork.swoogo.com/wind2025",
        demos: "All Day",
      },
    },
    {
      imgSrc: "/assets/images/our-solution/roof-con-logo-mod.png",
      title: "ROOFCON 2024",
      description:
        'RoofCON was created for forward-thinkers, trailblazers, top performers, and business owners in the roofing and solar industries who seek to define the marketplace today and tomorrow. In just a few years, RoofCON has become the #1 conference for the roofing and solar community! At RoofCON, we focus on GROWING both personally and professionally. We provide proven systems and next-level strategies to help our attendees develop as leaders." RoofCon',
      location: "Orange County Convention Center, Orlando, FL",
      info: {
        date: "Oct 23 - 25, 2024",
        website: "https://roofcon.com",
        demos: "All Day",
      },
    },
  ];

  return (
    <div className="w-full bg-[#f3fff4] pb-[200px] relative">
      <div className="w-full relative">
        <img
          alt="banner"
          src="/assets/images/blog/blog-banner.png"
          className="w-full relative h-[200px] lg:h-[780px] hidden xl:block"
        />
        <img
          alt="banner"
          src="/assets/images/common-banner.png"
          className="w-full relative h-[150px] lg:h-[550px] xl:hidden"
        />
        <div className="w-full p-4 absolute top-0 lg:top-[250px] left-0 right-0 mx-auto">
          <h1 className="text-2xl lg:text-[60px] font-['Montserrat'] leading-[70px] text-white inline-block relative">
            Stay up to Date
            <span className="font-bold relative ml-3 relative z-10">
            
              {/* <img
                src="/assets/images/blog/line-through.png"
                className="absolute right-0 top-0 w-full h-full z-[-1]"
              /> */}
            </span>
          </h1>
        </div>
        <img
          src="/assets/images/blog/half-circle-shape.png"
          className="absolute -bottom-[300px] right-0"
          alt="shape"
        />
      </div>

      <div className="w-full xl:-mt-[150px] p-4">
        <div className="text-center">
          <img
            src="/assets/images/blog/color-favi.png"
            alt="color-favi"
            className="block mx-auto lg:w-12 mb-4"
          />
          <GradientText
            classes={"uppercase text-center font-medium text-xl lg:text-[22px]"}
            // text={"DeltaClaims.AI"}
          ></GradientText>
          <h2 className="text-2xl lg:text-[50px] font-['Montserrat'] lg:leading-[55px] p-4 ">
            Our Latest <span className="font-bold"></span>
          </h2>
        </div>
      </div>

      <div className="w-full p-4 lg:p-0">
        <div className="w-full max-w-screen-xl mx-auto relative">
          <img
            src="/assets/images/blog/circular-dotted-shape.png"
            alt="shape"
            className="absolute -top-[200px] -left-[200px]"
          />
          <div
            style={{
              background:
                "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
              background:
                "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
              background:
                "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
              background:
                "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
            }}
            className="w-full bg-gradient-to-r from-[#59bb66] to-[#0195d8] p-0.5 rounded-3xl"
          >
            <div className="bg-white rounded-3xl flex flex-wrap lg:flex-nowrap gap-4 lg:gap-[50px]">
              <img
                src="/assets/images/blog/blog1.png"
                alt="blog1"
                className="w-full lg:w-6/12 rounded-t-3xl lg:border-l-3x z-50"
              />
              <div className="w-full lg:w-6/12 text-left p-4 lg:py-[60px] lg:pr-[60px]">
                <p
                  style={{
                    background:
                      "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    background:
                      "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    background:
                      "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    background:
                      "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    "-webkit-background-clip": "text",
                    "-webkit-text-fill-color": "rgba(255,255,255,0)",
                  }}
                  className="bg-gradient-to-r from-[#59bb66] to-[#0195d8] inline-block text-transparent 
                bg-clip-text text-[20px] font-semibold font-['Poppins']"
                >
                  April 2025
                </p>
                <h2 className="font-medium text-xl lg:text-[35px] font-['Montserrat'] lg:leading-[46px] mt-4 lg:mt-6">
                  Enhanced Feature: DeltaMate Version 2.0
                </h2>
                <p className="text-base font-medium font-['Montserrat'] my-6">
                DeltaClaims.AI is thrilled to announce the launch of DeltaMate Version 2, the latest upgrade to its cutting-edge claims analysis platform. This release introduces powerful new features designed to save time, enhance accuracy, and simplify estimate comparisons for contractors, adjusters, and attorneys.
                </p>
                <Button
                  fill={true}
                  text={"read more"}
                  type={"button"}
                  icon={false}
                  onClick={() =>
                    handleReadMore({
                      images: ["/assets/images/blog/blog1.png"],
                      title:
                        "Enhanced Feature: DeltaMate Version 2.0 - Revolutionizing Estimate Comparisons",
                      content:"DeltaClaims.AI is excited to announce the release of DeltaMate V2, a major update to our claims analysis platform designed to make estimate comparisons faster, more accurate, and easier than ever before. The new feature includes an enhanced ability to compare estimates by adding areas and groups, automatically calculating differences for quick insights. Users can now save their estimate comparisons directly within the system, allowing them to revisit and refine their work without starting from scratch. Additionally, DeltaMate V2 introduces the ability to export comparisons in Excel or CSV formats, making it simple to analyze data further or share it with team members and clients. These updates are designed to save time and improve accuracy for contractors, adjusters, and attorneys who rely on precise estimate analysis in their workflows. With this latest version, DeltaClaims.AI continues its mission to streamline claims processes and empower users with tools that deliver actionable insights in seconds. DeltaMate V2 is now live and available to all users. For more information or to schedule a demo, visit our website.",
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="grid lg:grid-cols-3 mt-[20px] gap-[20px] relative z-40">
            {blogs.map((blog, index) => {
              return (
                <div
                  style={{
                    background:
                      "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    background:
                      "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    background:
                      "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    background:
                      "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                  }}
                  className="w-full bg-gradient-to-r from-[#59bb66] to-[#0195d8] p-0.5 rounded-3xl"
                >
                  <div className="bg-white rounded-3xl w-full">
                    {/* <img
                      src={blog.images[0]}
                      alt="blog1"
                      className="w-full rounded-t-3xl"
                    /> */}
                    <div className="w-full text-center p-4 lg:p-6">
                      <p
                        style={{
                          background:
                            "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                          background:
                            "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                          background:
                            "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                          background:
                            "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                          "-webkit-background-clip": "text",
                          "-webkit-text-fill-color": "rgba(255,255,255,0)",
                        }}
                        className="bg-gradient-to-r from-[#59bb66] to-[#0195d8] inline-block text-transparent 
                            bg-clip-text text-[18px] font-semibold font-['Poppins']"
                        // onClick={() => handleReadMore(blog)}
                      >
                         Coming Soon
                      </p>
                      {/* <h2 className="font-medium text-xl lg:text-[30px] font-['Montserrat'] lg:leading-[46px] mt-4">
                        Future Blog
                      </h2>
                      <p className="text-base font-medium font-['Montserrat'] my-4">
                        Benefits of using DeltaClaims.AI.
                      </p> */}
                      <p
                        style={{
                          background:
                            "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                          background:
                            "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                          background:
                            "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                          background:
                            "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                          "-webkit-background-clip": "text",
                          "-webkit-text-fill-color": "rgba(255,255,255,0)",
                        }}
                        className="bg-gradient-to-r from-[#59bb66] to-[#0195d8] inline-block text-transparent 
                            bg-clip-text text-[20px] font-semibold font-['Poppins']"
                        // onClick={() => handleReadMore(blog)}
                      >
                        {/* {"Read more >>"} */}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="w-full pt-[80px] z-20 relative">
            <div className="w-full text-center flex items-center justify-center gap-2 mb-2">
              <img src="/assets/images/home/color-favi.png" alt="favi" />
              <GradientText
                classes={
                  "uppercase text-center font-medium text-xl lg:text-[22px]"
                }
                text={"conferences"}
              />
            </div>
            <h2 className="text-2xl lg:text-[50px] text-black font-['Montserrat'] leading-[55px] pb-6">
              Join the Conversation
              <span className="font-bold ml-3"></span>
            </h2>
            {conferences.map((conference, index) => {
              const conferenceClass =
                conference.title === "WINDSTORM 2024"
                  ? "windstorm-conference"
                  : "";

              return (
                <div
                  style={{
                    background:
                      "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    background:
                      "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    background:
                      "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    background:
                      "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                  }}
                  key={index}
                  className={`p-0.5 bg-gradient-to-r from-[#59bb66] to-[#0195d8] w-full rounded-2xl mb-[20px] ${conferenceClass}`}
                >
                  <div className="w-full flex flex-wrap lg:flex-nowrap gap-[20px] justify-between bg-white rounded-2xl h-full p-4 lg:p-[30px] text-left">
                    <img
                      className="w-full lg:w-[300px] h-auto object-contain rounded-2xl"
                      src={conference.imgSrc}
                      alt={conference.title}
                    />
                    <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-[25px] xl:divide-x divide-[#e5e5e5]">
                      <div className="w-full lg:pl-[25px] lg:pt-[30px]">
                        <h3 className="font-bold text-[18px] font-['Montserrat'] mb-3">
                          {conference.title}
                        </h3>
                        <p className="text-base font-['Montserrat'] break-words">
                          {conference.description}
                        </p>
                      </div>
                      <div className="w-full lg:pl-[25px] lg:pt-[30px]">
                        <h3 className="font-bold text-[18px] font-['Montserrat'] mb-3">
                          Location
                        </h3>
                        <div className="flex gap-2 items-center mb-2">
                          <p className="text-base font-['Montserrat']">
                            {conference.location}
                          </p>
                        </div>
                      </div>
                      <div className="w-full lg:pl-[25px] lg:pt-[30px]">
                        <h3 className="font-bold text-[18px] font-['Montserrat'] mb-3">
                          Conference Info
                        </h3>
                        <div className="flex gap-2 items-center mb-2">
                          <p className="text-base font-['Montserrat']">
                            {conference.info.date}
                          </p>
                        </div>
                        <div className="flex gap-2 items-center mb-2">
                          <p className="text-base font-['Montserrat'] break-words">
                            <a
                              href={conference.info.website}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-500 underline"
                            >
                              {conference.info.website}
                            </a>
                          </p>
                        </div>
                        <div className="flex gap-2 items-center mb-2">
                          <p className="text-base font-semibold font-['Montserrat']">
                            Live Demos:
                          </p>
                          <p className="text-base font-['Montserrat']">
                            {conference.info.demos}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

      {/* <img
        src="/assets/images/blog/bottom-wave.png"
        className="absolute left-0 bottom-0 lg:-bottom-[55px] z-1"
        alt="shape"
      /> */}
    </div>
  );
};

export default Blogs;
