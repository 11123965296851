import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Button from "../../components/ui/button"; // Adjust path if needed
import GradientText from "../../components/ui/gradient-text"; // Adjust path if needed

const FAQ = () => {
  // State to track which FAQ is currently expanded
  const [expandedIndex, setExpandedIndex] = useState(null);

  // useEffect(() => {
  //   const messages = [
  //     "Augment your workflows.",
  //     "Demand Quality Insights.",
  //     "Make enhanced decisions, faster.",
  //     "Maximize your claims capacity."
  //   ];
  //   const textElement = document.getElementById("animated-text");
  //   let index = 0;

  //   function changeText() {
  //     if (textElement) {
  //       textElement.textContent = messages[index];
  //       index = (index + 1) % messages.length;
  //     }
  //   }

  //   changeText();
  //   const interval = setInterval(changeText, 4000);

  //   return () => clearInterval(interval); // Cleanup on unmount
  // }, []);

  const sequence = [
    {
      icon: "/assets/images/our-solution/icon5.png",
      text: "What does DeltaClaims.AI do?",
      list: ["We are committed to enhancing workflows, streamlining processes, and improving efficiency—while complementing the invaluable talent within our users' teams. We optimize our clients' claims management process with digital solutions for efficiently transparent policy analysis and estimate comparison as our in-house AI Chatbot ties together a seamless workstation for maximizing time and resources. As we simplify the approach to understanding needs for each given claim, we're here to maximize the value our clients give and take throughout their organization's claim management."],
    },
    {
      icon: "/assets/images/our-solution/icon5.png",
      text: "Who uses DeltaClaims.AI?",
      list: [
        "For insurers or representatives of the insured, DeltaClaims.AI is designed to bring clarity and efficiency to the claims dispute process. Our AI-powered product suite streamlines policy analysis, estimate comparisons, and case-specific insights—eliminating uncertainty and accelerating resolutions with transparency and precision.",
      ],
    },
    {
      icon: "/assets/images/our-solution/icon5.png",
      text: "Why do I need DeltaClaims.AI?",
      list: [
        "With growing complexity in the world of claims, we've paved the way to a powerfully simple industry solution.  We work to optimize client workflows by creating space for proven processes to overlap with a uniquely powerful AI-tool. Clients take advantage of leading technology in an industry where processes become inefficient if not aligned with the available resources.",
      ],
    },
    {
      icon: "/assets/images/our-solution/icon5.png",
      text: "What estimate formats does DeltaMate® support?",
      list: [
        "With Xactimate producing over 50 formats of estimate reports, DeltaClaims.AI prioritizes those that provide the most valuable data for its users. With continuous learning, our system continues to enhance the quality and quantity of its insights for the unique needs of its users.",
      ],
    },
  ];

  const handleCardClick = (index) => {
    // Toggle the expanded state - if clicking on already expanded card, close it
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div className="w-full relative">
      {/* Header Section */}
      <div className="w-full relative">
        <img
          alt="banner"
          src="/assets/images/team/team-shape-banner.png"
          className="w-full absolute -bottom-[55px] lg:bottom-0 lg:relative z-10 lg:h-[750px]"
        />
        <div className="w-full p-4 bg-[#001235] lg:bg-transparent lg:absolute top-0 left-0 right-0 mx-auto h-full z-10">
          <img
            alt="banner"
            src="/assets/images/home/home-banner-shadow.png"
            className="absolute left-0 top-0 hidden xl:block"
          />
          <div className="w-full max-w-screen-xl mx-auto flex items-center flex-wrap md:flex-nowrap h-full justify-left">
            <div className="w-full lg:w-5/12 text-white text-center lg:text-left relative z-10">
              <h1 className="text-2xl lg:text-[60px] font-['Montserrat'] capitalize lg:leading-[70px] mb-8">
                FAQs
              </h1>
              <Button
                fill={true}
                text={"Ask a Question"}
                type={"button"}
                icon={true}
                onClick={() =>
                  (window.location.href =
                    "https://47142898.hs-sites.com/en/deltaclaims.ai-contact-us")
                }
              />
            </div>
            <div className="w-full relative font-italic lg:text-[60px] z-10 text-white font-['Montserrat'] lg:w-6/12 pt-12 pl-8 pb-14">
              {/* Animated text container */}
              <div className="heroSection">
                <div className="textContainer">
                  <h2 id="animated-text" className="animatedText"></h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* FAQ Section */}
      <div className="w-full pt-[50px] relative">
        <img
          src="/assets/images/our-solution/wave-right.png"
          alt="wave-right"
          className="absolute -top-[200px] right-0"
        />
        <img
          src="/assets/images/our-solution/half-circle.png"
          alt="wave-right"
          className="absolute top-[200px] right-0"
        />
        <img
          src="/assets/images/our-solution/left-wave.png"
          alt="left-wave"
          className="absolute top-[50%] left-0"
        />
        <div className="w-full max-w-screen-xl mx-auto relative z-10 p-4 xl:p-0">
          <div className="w-full flex items-center justify-between flex-wrap lg:flex-nowrap">
            <div className="text-center lg:text-left w-full">
              <div className="w-full flex items-center justify-center lg:justify-start gap-2">
              </div>
              <h2 className="text-2xl lg:text-[50px] text-black font-['Montserrat'] lg:leading-[55px] py-3">
                Frequently Asked Questions
                <span className="font-bold ml-3"></span>
              </h2>
              <p className="font-['Montserrat'] text-base">
                
              </p>
            </div>
            <div className="flex flex-wrap xl:flex-nowrap items-center gap-4 w-full justify-center lg:justify-end mt-4 lg:mt-0">
              <NavLink to="/what-is-ai">
                {/* Button content commented out */}
              </NavLink>
              {/* /* Button commented out */}
                    </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 w-full gap-[24px] pt-[25px] relative pb-20">
                    {/* <img
                      src="/assets/images/our-solution/arrows.png"
                      alt="arrows"
                      className="hidden 2xl:block absolute top-[200px] -right-[55px]"
                    /> */}
            {sequence.map((data, index) => {
  const isExpanded = expandedIndex === index;
  
  return (
    <div
      style={{
        background: "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
      }}
      key={index}
      className="p-0.5 bg-gradient-to-r from-[#59bb66] to-[#0195d8] w-full rounded-2xl mb-4"
      onClick={() => handleCardClick(index)}
    >
      <div
        className={`w-full bg-white rounded-2xl p-[25px] text-left relative overflow-visible cursor-pointer transition-all duration-300 ease-in-out ${
          isExpanded ? "max-h-none" : "min-h-[200px]"
        }`}
      >
        {/* Icon and Number */}
        <div className="flex justify-between">
          <img
            className="w-[70px] h-[70px] object-contain ml-2 md:w-[70px] md:h-[70px] sm:w-[50px] sm:h-[50px]"
            src={data.icon}
            alt={data.icon}
          />
          <img
            className="h-[90px] object-contain md:h-[90px] sm:h-[70px]"
            src={data.number}
            alt={data.number}
          />
        </div>
        {/* Question Text */}
        <p className="font-semibold font-['Montserrat'] text-[20px] mt-6 mb-4 md:text-[30px] sm:text-[24px] break-words hyphens-auto">
          {data.text}
        </p>
        {/* Overlay that appears on hover/click */}
          <div
            className={`absolute inset-0 bg-gradient-to-r from-[#59bb66] to-[#0195d8] p-[25px] flex flex-col transition-all duration-300 rounded-2xl ${
              isExpanded
                ? "opacity-95 translate-y-0 h-auto min-h-full overflow-y-auto"
                : "opacity-0 translate-y-full pointer-events-none"
            }`}
          >
            <p className="font-bold font-['Montserrat'] text-[22px] text-white mb-4 md:text-[22px] sm:text-[18px] break-words hyphens-auto">
              {/* {data.text} */}
          </p>
          <div className="flex flex-col gap-3">
            {data.list.map((item, itemIndex) => (
              <div key={itemIndex} className="flex gap-3 items-start">
                <div className="h-2 w-2 rounded-full bg-white flex-shrink-0 mt-2"></div>
                <p className="text-base font-['Montserrat'] text-white">
                  {item}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
})}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;