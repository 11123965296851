import React, { useState, useEffect } from "react";
import GradientText from "../../components/ui/gradient-text";
import Button from "../../components/ui/button";
import Newsletter from "../../components/newsletter/newsletter";
//adding react-helmet
import { Helmet } from "react-helmet";
import "../../homedirectory.css";

const Home = () => {
  const [isHovered, setIsHovered] = useState(false);
    const images = [
        //"/assets/images/home/b1.png",
        "/assets/images/home/b2.png"
        //"/assets/images/home/b3.png",
    ];
    // const benefits = {
    //   items: [
    //     {
    //       title: "Upload All Relevant Claim Files ",
    //       description:
    //         "From multiple sources, upload Estimates, Policy, Reports, and other documents to pursue claims.",
    //       image: "/assets/images/our-solution/upload-files.jpg",
    //     },
    //     {
    //       title: "Auto File Classification",
    //       description:
    //         "DeltaClaims.AI automatically categorizes and tags files for easy retrieval and organization.",
    //       image: "/assets/images/home/DocHub1.png",
    //     },
    //     {
    //       title: "Policy Analysis and Summary",
    //       description:
    //         "High-speed, high-quality analysis and description of key insurance policy details.",
    //       image: "/assets/images/home/policy-page.png",
    //     },
    //     {
    //       title: "DeltaMate® Estimate Comparison and Analysis",
    //       description:
    //         "Quickly compare any two PDF estimates side by side with detailed analysis of items and their costs.",
    //       image:
    //         "/assets/images/home/DeltaMate.png",
    //     },
    //     {
    //       title: "Eureka - AI ChatBot Claims Assistant",
    //       description:
    //         "Ask detailed, claim-specific questions and request information to be applied in meaningful ways.",
    //       image: "/assets/images/our-solution/chatbot-page.jpg",
    //     },
    //   ],
    // };
  const headerStyle = {
    background: 'linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)',
    padding: '60px 20px',
    textAlign: 'center',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
    position: 'relative',
    padding: '30px',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    transform: isHovered ? 'translateY(-10px)' : 'translateY(0)',
    boxShadow: isHovered 
      ? '0 15px 30px rgba(0, 0, 0, 0.2)' 
      : '0 4px 6px rgba(0, 0, 0, 0.1)',
    borderRadius: '12px',
  };

  const headerContentStyle = {
    maxWidth: '1200px',
    margin: '0 auto',
  };

  const headerTitleStyle = {
    fontSize: '2.2rem',
    fontWeight: 500,
    color: '#fff',
    textalign: 'center',
    letterSpacing: '2px',
    fontFamily: "'Montserrat', sans-serif",
  };

  const introSectionStyle = {
    width: '100%',
    maxWidth: '1400px',
    backgroundColor: '#001235',
    color: '#001235',
    padding: '15px',
    borderRadius: '12px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    marginTop: '40px',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  };

  const paragraphStyle = {
    fontSize: '2.5rem',
    fontWeight: 400,
    fontFamily: "'Montserrat', sans-serif",
    textAlign: 'center',
    color: '#fff',
  };
  
    return (
        <div className="w-full relative">
            <Helmet>
                <title>DeltaClaims.AI | AI-Driven Claims Adjustment and Management Services</title>
                <meta name="description" content="DeltaClaims.AI offers cloud-native, AI-powered solutions for insurance claims adjustment and management." />
                <meta
                    name="keywords"
                    content="AI claims adjustment, insurance claims automation, cloud-native claims processing, machine learning in insurance, DeltaClaims AI"
                />
                <meta property="og:title" content="DeltaClaims.AI - Revolutionizing Claims Management with AI" />
                <meta property="og:description" content="AI-powered claims adjustment solutions for faster, more efficient claims management." />
                <meta property="og:url" content="https://www.deltaclaims.ai" />
                <meta property="og:image" content="https://www.deltaclaims.ai/assets/images/logo.png" />
                <meta name="robots" content="index,follow" />
            </Helmet>
            <div className="w-full relative">
                <img alt="banner" src="/assets/images/team/team-shape-banner.png" className="w-full absolute -bottom-12 lg:bottom-0 lg:relative lg:h-[900px]" />
                <img alt="banner" src="/assets/images/home/home-banner-shadow.png" className="absolute left-0 top-0 hidden lg:block" />
                <div className="w-full p-4 bg-[#001235] lg:bg-transparent lg:absolute top-0 left-0 right-0 mx-auto h-full">
                    <div className="w-full max-w-screen-2xl mx-auto flex items-center flex-wrap md:flex-nowrap h-full">
                        <div className="w-full lg:w-5/12 text-white text-center lg:text-left relative">
                            <GradientText classes={"uppercase font-bold text-xl lg:text-2xl"} text={"welcome to deltaclaims.ai"}></GradientText>
                            <h1 className="text-2xl lg:text-[60px] font-['Montserrat'] capitalize leading-9 lg:leading-[70px] mb-4 lg:my-8">
                                We Make Dispute Resolution
                                <span className="font-bold"> seamless </span>
                                <span className="font-bold relative mr-2">
                                    <img alt="line" src="/assets/images/home/line-through.png" className="absolute left-0 top-0 w-full" />
                                </span>
                            </h1>

              <div className="flex justify-center lg:justify-start">
                <Button
                  fill={true}
                  text={"Schedule a Demo"}
                  type={"button"}
                  icon={true}
                  onClick={() =>
                    (window.location.href =
                      "https://meetings.hubspot.com/nikolaos-stafford/productdemoscheduler")
                  }
                />
              </div>
            </div>
            <div className="w-full lg:w-10/12 justify-center">
              <img className="w-[90%] h-full relative pt-12" alt="banner" src="/assets/images/home/HomeDesign.svg"/>
            </div>
          </div>
        </div>
      
      </div>
      <div style={headerStyle} onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)} className="mt-[100px]">
      <div style={headerContentStyle}>
        <h1 style={{ ...paragraphStyle}}>Experience the Premiere AI-Driven Claims Management Suite</h1>
      </div>
    </div>

      {/* <div className="w-full max-w-screen-xl mx-auto my-10 p-4 lg:p-0">
        <div className="w-full max-w-screen-xl mx-auto py-10 lg:py-0 my-10 p-4">
          <h2 className="text-2xl lg:text-[40px] text-black p-6 font-['Montserrat'] leading-[55px] pb-2">
          <span className="font-semibold ml-3">Walk through the Premiere AI-Driven Claims Management Workstation</span>
              </h2>
            
          
        </div> */}
        {/* removed image and added coming soon text*/}
        {/* <div className="w-full max-w-screen-xl mx-auto py-10 lg:py-0 my-10 p-4"> */}
          {/* <GradientText
            classes={"text-center font-bold text-lg lg:text-[26px]"}
            text={"TRANSFORMING HOURS INTO MINUTES"}
          ></GradientText> */}
        {/* </div>
      </div> */}
      <div className="w-full max-w-screen-xl mx-auto my-10 p-4 lg:p-0">
      <video width="1600" height="900" style={{ borderRadius: "15px" }} muted controls className="pt-16" preload="metadata" poster="/assets/images/home/Home-Graphic.png">
                <source src="/assets/images/home/FullDemo2.mp4" type="video/mp4" />
              </video>
              </div>

      <div className="w-full pt-[80px] pb-[40px]">
   
      <div style={headerStyle} onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)} className="pb-[20px]">
      <div style={headerContentStyle}>
        <h1 style={{ ...paragraphStyle}}>See How DeltaClaims Equips Peers in your Role</h1>
      </div>
    </div>
            {/* <div className="w-full flex flex-col gap-8">
              {benefits.items.map((item, index) => (
                <div key={index} className="flex flex-col lg:flex-row gap-8">
                  <div className="w-full lg:w-[26%] flex items-center">
                    <div>
                      <h3 className="text-xl font-['Montserrat'] font-semibold ml-4 mb-3">
                        {item.title}
                      </h3>
                      <p className="font-['Montserrat'] text-base text-center p-2 ml-2">
                        {item.description}
                      </p>
                    </div>
                  </div>
                  <div className="w-full lg:w-[70%]">
                    <div
                      className="p-0.5 bg-gradient-to-r from-[#59bb66] to-[#0195d8] rounded-2xl w-full"
                      style={{
                        background:
                          "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                      }}
                    >
                      <div className="w-full bg-white rounded-2xl min-h-[400px] flex items-center justify-center p-4">
                        <img
                          src={item.image}
                          alt={item.title}
                          className="w-full h-full object-cover"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div> */}
                <div className="leadership-insights pt-[100px]">
                    <div className="header-container">
                        <h2 className="main-title text-center font-['Montserrat'] pt-[20px]"></h2>
                        {/* <button className="read-more-btn">Read more</button> */}
                    </div>

                    <div className="cards-container">
                        <div className="insight-card" data-color="blue-60">
                            <span className="card-number"></span>
                            <h3 className="executive-name">Adjusters</h3>
                            <p className="executive-title"></p>
                            <div className="hover-content">
                                <p className="insight-question">How do you allocate time and resources?</p>
                                <a href="/adjusters">
                                    <button className="read-article-btn">Learn More</button>
                                </a>
                            </div>
                        </div>

                        <div className="insight-card" data-color="blue-40">
                            <span className="card-number"></span>
                            <h3 className="executive-name">Attorneys</h3>
                            <p className="executive-title"></p>
                            <div className="hover-content">
                                <p className="insight-question">How do you balance overhead costs and productivity?</p>
                                <a href="/attorneys">
                                    <button className="read-article-btn">Learn More</button>
                                </a>
                            </div>
                        </div>

                        <div className="insight-card" data-color="blue-20">
                            <span className="card-number"></span>
                            <h3 className="executive-name">Contractors</h3>
                            <p className="executive-title"></p>
                            <div className="hover-content">
                                <p className="insight-question">How do you approach taking on projects?</p>
                                <a href="/contractors">
                                    <button className="read-article-btn">Learn More</button>
                                </a>
                            </div>
                        </div>

                        <div className="insight-card" data-color="blue">
                            <span className="card-number"></span>
                            <h3 className="executive-name">Insurers/TPAs</h3>
                            <p className="executive-title"></p>
                            <div className="hover-content">
                                <p className="insight-question">How do you enhance risk visibility?</p>
                                <a href="/insurers-tpa">
                                    <button className="read-article-btn">Learn More</button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full pb-[150px] flex justify-center pt-10">
                <Button type={"button"} text={"All Features"} fill={true} icon={true} onClick={() => (window.location.href = "/our-solution")} />
            </div>

            <div className="w-full relative my-10 lg:my-[70px] p-4 lg:p-0">
                <img src="/assets/images/home/home-right-shape.png" className="absolute right-0 top-0" alt="shape" />
                <div className="flex flex-wrap lg:flex-nowrap items-center w-full max-w-[1800px] mx-auto relative">
                    <div className="w-full lg:w-7/12 relative mb-100">
                        <img className="" src="/assets/images/home/banner-2.png" alt="text" />
                    </div>
                    <div className="w-fu lg:w-4/12 text-left">
                        <div className="flex gap-2 items-center">
                            <img src="/assets/images/home/color-favi.png" alt="text" />
                            <GradientText classes={"uppercase text-center font-medium text-xl lg:text-[22px]"} text={"join us"}></GradientText>
                        </div>
                        <h2 className="text-2xl lg:text-[50px] font-['Montserrat'] leading-9 lg:leading-[55px] py-3 ">
                            Our Mission at
                            <br />
                            <span className="font-bold">DeltaClaims.AI</span>
                        </h2>
                        <p className="text-base font-['Montserrat] pb-[30px] lg:pr-[120px]">
                            Our purpose is to empower claims professionals through innovative, tailor-made AI solutions. Our AI simplifies the complex world of
                            insurance claims assisting insurance professionals and general contractors with efficient, secure, and accurate information about
                            their claim.
                        </p>
                        <a href="/login-signup">
                            <button>
                                <Button fill={true} text={"get access"} type={"button"} icon={true} />
                            </button>
                        </a>
                    </div>
                </div>
            </div>

            <div className="w-full max-w-screen-xl mx-auto my-10 relative z-50 p-4 lg:p-0">
                <Newsletter />
            </div>
            <img src="/assets/images/home/home-bottom-shape.png" className="absolute left-0 bottom-0" alt="shape" />
            <img src="/assets/images/home/home-bottom-shape-2.png" className="absolute left-0 bottom-[200px]" alt="shape" />
        </div>
    );
};

export default Home;
