import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const ContextMenu = ({ isMobile }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        if (isMobile) {
            setIsDropdownOpen(!isDropdownOpen);
        }
    };

    // Desktop version - glassmorphic style with absolute positioning
    if (!isMobile) {
        return (
            <>
                <span
                    className="block py-2 text-white cursor-default relative overflow-hidden rounded-lg transition-all duration-300
                    hover:bg-white/10 hover:backdrop-blur-sm px-4">
                    Our Company
                </span>

                <div
                    className="absolute left-0 invisible opacity-0 group-hover:visible group-hover:opacity-100 
                    backdrop-blur-lg bg-gray-900/75 text-white 
                    shadow-[0_8px_32px_rgba(0,18,53,0.3)] border border-white/20
                    rounded-2xl mt-3 font-montserrat w-52 overflow-hidden 
                    transition-all duration-300 ease-out transform origin-top-left -translate-y-2 group-hover:translate-y-0 scale-95 group-hover:scale-100">
                    <div className="relative overflow-hidden">
                        {/* Subtle glass effect overlay */}
                        <div className="absolute inset-0 bg-gradient-to-br from-black/40 to-transparent pointer-events-none"></div>

                        <NavLink
                            to="/team"
                            className={({ isActive }) =>
                                `relative block px-6 py-3.5 text-sm font-medium transition-all duration-200 ${
                                    isActive ? "bg-gradient-to-r from-gray-700/70 to-black/60 text-white" : "text-white hover:bg-black/30"
                                }`
                            }>
                            <span className="relative z-10">Meet the Team</span>
                            <div className="absolute inset-0 opacity-0 hover:opacity-100 bg-gradient-to-r from-gray-600/40 to-black/30 transition-opacity duration-300"></div>
                        </NavLink>

                        <div className="h-px bg-gradient-to-r from-transparent via-gray-500/50 to-transparent"></div>

                        <NavLink
                            to="/blog"
                            className={({ isActive }) =>
                                `relative block px-6 py-3.5 text-sm font-medium transition-all duration-200 ${
                                    isActive ? "bg-gradient-to-r from-gray-700/70 to-black/60 text-white" : "text-white hover:bg-black/30"
                                }`
                            }>
                            <span className="relative z-10">News</span>
                            <div className="absolute inset-0 opacity-0 hover:opacity-100 bg-gradient-to-r from-gray-600/40 to-black/30 transition-opacity duration-300"></div>
                        </NavLink>

                        <div className="h-px bg-gradient-to-r from-transparent via-gray-500/50 to-transparent"></div>

                        <NavLink
                            to="/what-is-ai"
                            className={({ isActive }) =>
                                `relative block px-6 py-3.5 text-sm font-medium transition-all duration-200 ${
                                    isActive ? "bg-gradient-to-r from-gray-700/70 to-black/60 text-white" : "text-white hover:bg-black/30"
                                }`
                            }>
                            <span className="relative z-10">Why AI?</span>
                            <div className="absolute inset-0 opacity-0 hover:opacity-100 bg-gradient-to-r from-gray-600/40 to-black/30 transition-opacity duration-300"></div>
                        </NavLink>
                    </div>
                </div>
            </>
        );
    }

    // Mobile version - standard dropdown with display none/block
    return (
        <>
            <span
                onClick={toggleDropdown}
                className="block py-2 text-white cursor-pointer relative overflow-hidden rounded-lg transition-all duration-300
                hover:bg-white/10 px-4 flex justify-between items-center">
                Our Company
                <svg
                    className={`w-4 h-4 ml-2 transition-transform ${isDropdownOpen ? "rotate-180" : ""}`}
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                    />
                </svg>
            </span>

            {/* Simple display none/block for mobile */}
            <div className={`w-full overflow-hidden transition-all duration-300 ${isDropdownOpen ? "max-h-60" : "max-h-0"}`}>
                <div className="pl-4 border-l border-white/20 ml-4 mt-2 space-y-2">
                    <NavLink
                        to="/team"
                        className={({ isActive }) =>
                            `block py-2 text-white relative overflow-hidden rounded-lg transition-all duration-300
                            hover:bg-white/10 px-4 ${isActive ? "bg-white/5" : ""}`
                        }>
                        Meet the Team
                    </NavLink>

                    <NavLink
                        to="/blog"
                        className={({ isActive }) =>
                            `block py-2 text-white relative overflow-hidden rounded-lg transition-all duration-300
                            hover:bg-white/10 px-4 ${isActive ? "bg-white/5" : ""}`
                        }>
                        News
                    </NavLink>

                    <NavLink
                        to="/what-is-ai"
                        className={({ isActive }) =>
                            `block py-2 text-white relative overflow-hidden rounded-lg transition-all duration-300
                            hover:bg-white/10 px-4 ${isActive ? "bg-white/5" : ""}`
                        }>
                        Why AI?
                    </NavLink>
                </div>
            </div>
        </>
    );
};

export default ContextMenu;
