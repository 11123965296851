import Button from "../../components/ui/button";
import GradientText from "../../components/ui/gradient-text";
import React from "react";

const Team = () => {
    const members = [
        {
            name: "Will Perez, Ph.D.",
            title: "Co-Founder, CTO",
            img: "/assets/images/team/will-photo.png",
            bio: `Will Perez is one of the founders of DeltaClaims.AI, bringing extensive experience in AI and cloud computing to the insurance claims industry. Previously, he worked as an AI solutions architect at one of the largest tech companies, specializing in machine learning and automation. Now, he is focused on leveraging AI to transform claims processing and drive innovation in the industry.`,
            linkedin: "https://www.linkedin.com/in/cloudcyberguyphd"
        },
        {
            name: "Don McChesney",
            title: "Co-Founder",
            img: "/assets/images/team/don-photo.png",
            bio: `Don McChesney is one of the founders of DeltaClaims.AI and a serial entrepreneur in several industries. His extensive experience in the education and technology sectors has recently led him to the insurance claims industry with an unparalleled AI solution.`,
            linkedin: "https://www.linkedin.com/in/don-mcchesney-24560247"
        },
        {
            name: "Michael Deckelman",
            title: "Chief Operating Officer",
            img: "/assets/images/team/michaeld-photo.png",
            bio: `Michael Deckelman is the Chief Operating Officer at DeltaClaims.AI. Since 2021, he has worked as a public adjuster and appraiser across multiple states, handling thousands of residential and commercial claims. With extensive expertise in construction and insurance claims, Michael brings invaluable industry knowledge to DeltaClaims.AI.
            Michael is committed to refining and tailoring the platform to meet your needs.`,
            linkedin: "https://www.linkedin.com/in/michael-deckelman-44221a59"
        },
        {
            name: "Nikolas Stafford",
            title: "VP of Sales and Marketing",
            img: "/assets/images/team/nick-photo.png",
            bio: `Nikolaos is at the forefront of promoting the company’s innovative use of AI to transform the claims management process. With his extensive background in finance, operations, and marketing, he is uniquely positioned to drive Delta Claims AI’s strategic growth and establish the company as a leader in the claims tech industry.`,
            linkedin: "https://www.linkedin.com/in/nikolaos-stafford-3b2933bb"
        }
    ];

    return (
        <div className="w-full relative bg-[#001235]">
            <img alt="banner" src="/assets/images/home/home-banner-shadow.png" className="absolute left-0 top-0 z-19 hidden xl:block " />
            <div className="w-full relative bg-[#001235] z-10">
                <img alt="banner" src="/assets/images/team/team-shape-banner.png" className="w-full absolute lg:relative z-10 lg:h-[750px] " />
                <div className="w-full p-4 bg-[#001235] lg:bg-transparent lg:absolute top-0 left-0 right-0 mx-auto h-full z-10">
                    <div className="w-full max-w-screen-2xl mx-auto flex items-center flex-wrap md:flex-nowrap h-full">
                        <div className="w-full lg:w-5/12 text-white text-center lg:text-left relative z-10">
                            <h1 className="text-2xl lg:text-[60px] font-['Montserrat'] capitalize lg:leading-[70px] mb-8">
                                meet the
                                <span className="font-bold relative ml-3">
                                    Team
                                    <img src="/assets/images/home/line-through.png" alt="sol" className="absolute left-0 top-0 w-full h-full z-[-1]" />
                                </span>
                            </h1>
                            <Button fill={true} text={"get started with us"} type={"button"} icon={true} onClick={() => window.location.href = 'https://meetings.hubspot.com/nikolaos-stafford/productdemoscheduler'}/>
                        </div>
                        <div className="w-full lg:w-6/12 lg:pt-[100px] relative z-10">
                            <img width="720" height="480" alt="banner" src="/assets/images/team/team3-shadow.png" className="w-full" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full relative bg-[#001235] pt-20">
                <img src="/assets/images/team/team-right-shape.png" className="absolute top-[-200px] right-0" alt="team-right-shape" />

                <div className="w-full text-center flex items-center justify-center gap-2 relative z-40">
                    <img src="/assets/images/home/color-favi.png" alt="favi" />
                    <GradientText classes={"uppercase text-center font-medium text-xl lg:text-[22px]"} text={"leadership & management"}></GradientText>
                </div>
                <h2 className="text-2xl lg:text-[50px] text-white font-['Montserrat'] lg:leading-[55px] py-3 ">
                    Meet The
                    <span className="font-bold ml-3">Team</span>
                </h2>

                <div className="w-full max-w-screen-xl mx-auto lg:pt-10 p-4">
                    <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-5">
                        {members.map((member, index) => (
                            <div key={index} className="w-full">
                                <div
                                    style={{
                                        background: "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)"
                                    }}
                                    className="bg-gradient-to-r from-[#59bb66] to-[#0195d8] p-0.5 rounded-3xl">
                                    <div className="w-full rounded-3xl bg-[#0d1e3f] pt-1 pr-1 pl-1 overflow-hidden relative group">
                                        {index === 0 && (
                                            <img src="/assets/images/team/team1-shadow.png" alt="shadow" className="absolute left-[-140px] top-[-140px] z-10" />
                                        )}
                                        {index === 2 && (
                                            <img src="/assets/images/team/team3-shadow.png" alt="shadow" className="absolute right-[-140px] top-[-140px]" />
                                        )}
                                        <img
                                            src={member.img}
                                            className="block mx-auto relative z-10 w-full object-cover object-bottom lg:h-[430px]"
                                            alt="team"
                                        />

                                        {/* Bio overlay */}
                                        <div className="absolute inset-0 bg-gradient-to-b from-[#59bb66]/95 to-[#0195d8]/95 opacity-0 group-hover:opacity-100 transition-all duration-300 flex items-center justify-center z-20">
                                            <div className="p-4 lg:p-6">
                                                <p className="text-white text-sm lg:text-base xl:text-lg font-['Montserrat'] leading-tight transform translate-y-4 group-hover:translate-y-0 transition-transform duration-300">
                                                    {member.bio}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className="w-full text-[30px] font-semibold font-['Montserrat'] mt-6 mb-4 text-white">
                                    {member.name}
                                    <br />
                                    {member.title}
                                </p>

                                {/* Social Links */}
                                <div className="flex items-center gap-4 justify-center mt-4">
                                    <a
                                        href={member.linkedin}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="bg-gradient-to-r from-[#59bb66] to-[#0195d8] w-[50px] h-[50px] p-3 rounded-full flex items-center justify-center"
                                    >
                                        <img className="w-full text-white" src="/assets/images/team/linkedin.svg" alt="LinkedIn" />
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <img
                    src="/assets/images/team/team-pixel.png"
                    className="relative bottom-[-50px] sm:bottom-[-75px] lg:-bottom-[130px] xl:-bottom-[200px] bg-[#001235] w-full left-0"
                    alt="pixel"
                />
            </div>
        </div>
    );
};

export default Team;
