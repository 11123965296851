import React, { useEffect } from "react";

const TermsOfUse = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full bg-[#f3fff4] relative">
      <div className="w-full relative">
        <img
          alt="banner"
          src="/assets/images/common-banner.png"
          className="w-full relative h-[150px] lg:h-[550px]"
        />
        <div className="w-full p-4 absolute top-0 lg:top-[250px] left-0 right-0 mx-auto">
          <h1 className="text-2xl lg:text-[60px] font-['Montserrat'] leading-[70px] text-white inline-block relative">
            Terms of Use
            <span className="font-bold relative ml-3 z-10">
              <img
                alt="line"
                src="/assets/images/blog/line-through.png"
                className="w-full absolute top-[-6px] lg:-top-[20px] left-0 z-[-1]"
              />
            </span>
          </h1>
        </div>
      </div>

      <div className="w-full max-w-screen-xl mx-auto my-10 border-b-2 border-[#d9d9d9] py-10">
        <div className="w-full p-4">
          <h2 className="text-center text-xl lg:text-[22px] font-bold">Terms of Use</h2>
          <p className="text-left text-md lg:text-lg mt-6">Please read these Terms of Use (hereinafter, “Terms”) carefully before using the <a href="https://www.deltaclaims.ai">www.deltaclaims.ai</a> website and any and all programs and platforms offered thereon (collectively, the “Website”) operated by
Deltaclaims.AI, LLC (“us”, “we”, “our”, or “DeltaClaims”).</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6">Your access to and use of the Website is conditioned upon your acceptance of and compliance with these
Terms. These Terms apply to all visitors, users and parties who access or use the Website. By accessing
or using the Website you acknowledge, and agree to be bound by, these Terms along with DeltaClaims'
Privacy Policy and any other terms, policies, and user guidelines that apply to user's use of the Website.
The content of the pages of the Website is for your general information and use only. It is subject to change
without notice.</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6">Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness,
performance, completeness or suitability of the information, materials, products, programs, platforms, and
services found or offered on the Website for any particular purpose. You acknowledge that such
information, materials, and services may contain inaccuracies or errors, and we expressly exclude liability
for any such inaccuracies or errors to the fullest extent permitted by law. Your use of any information or
materials on the Website is entirely at your own risk, for which we shall not be liable. It shall be your own
responsibility to ensure that any information, materials, products, programs, platforms, and services
available through the Website meet your specific requirements.</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6 font-bold">By submitting and retrieving information from this website, you acknowledge that DeltaClaims.AI is not
providing legal advice nor participating in or supporting the act of unauthorized public adjusting or the
unauthorized practice of law. Our services are designed to analyze documents to enhance insurance
professionals and general contractors' understanding of insurance claims through advanced AI
technology. It is your responsibility to ensure that your use of our services complies with all applicable
laws and regulations. Additionally, you are responsible for ensuring that you have all licenses required by
any federal, state, or municipal regulation.</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6">The Website may contain material which is owned by or licensed to us. This material includes but is not
limited to, the design, layout, look, appearance, graphics, and content appearing on the Website.
Reproduction is prohibited other than in accordance with the copyright notice herein, which forms part of
these terms and conditions.</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6">All trademarks reproduced in the Website, which are not the property of, or licensed to DeltaClaims, are
acknowledged on the Website. The unauthorized use of any trademarks appearing on the Website, whether
belonging to DeltaClaims or its affiliates, related companies, vendors, suppliers, retailers, or other third
parties is strictly prohibited in the absence of a valid license from the trademark owner(s).</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6">Unauthorized use of the Website may give rise to a claim for damages and/or be a criminal offence.</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6">From time to time, the Website may contain links to other mobile applications or websites that are not
covered by these Terms or DeltaClaims' privacy policy and where information practices may be different
from ours. Our provision of a link to any other website or location is for your convenience and does not
signify our endorsement of such other website or location or its contents. Please be aware that these Terms
and DeltaClaims' privacy policy do not apply to these outside websites or content, or to any collection of
data after you click on links to such outside websites.</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6">Although we have determined that this Website complies with the laws of the United States of America, by
using this Website and/or making purchases of any of our services or products, you agree that venue for
any disputes arising under these Terms, DeltaClaims' privacy policy, or your use of the Website, generally,
shall be in a court of competent jurisdiction within the State of Florida.</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6 font-bold">Availability, Errors, and Inaccuracies</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6">We are constantly updating product and service offerings on the Website. We may experience delays in
updating information on the Website and in our advertising on other websites or mobile applications. The
information found on the Website may contain errors or inaccuracies and may not be complete or current.
Products or services may be mispriced, described inaccurately, or unavailable on the Website and we
cannot guarantee the accuracy or completeness of any information found on the Website. We therefore
reserve the right to change or update information and to correct errors, inaccuracies, or omissions at any
time without prior notice.</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6 font-bold">Accounts</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6">When you create an account with us, you guarantee that you are above the age of 18, and that the
information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or
obsolete information may result in the immediate termination of your account on the Website.
You are responsible for maintaining the confidentiality of your account and password, including but not
limited to the restriction of access to your computer, mobile device, and/or user account. You agree to
accept responsibility for any and all activities or actions that occur under your account and/or password,
whether your password is with our Website or a third-party service. You must notify us immediately upon
becoming aware of any breach of security or unauthorized use of your account.</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6">You may not use as a username the name of another person or entity or that is not lawfully available for
use, a name or trademark that is subject to any rights of another person or entity other than you, without
appropriate authorization. You may not use as a username any name that is offensive, vulgar or obscene.
We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders in our
sole discretion.</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6 font-bold">Intellectual Property</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6">Excluding any content that you or another user may provide (“User Content”), you acknowledge and agree
that all intellectual property rights, including copyrights, patents, trademarks, and trade secrets, on or in any
part of the Website and in all other content thereon are owned by DeltaClaims or DeltaClaims' affiliates,
related companies, suppliers, vendors, retailers, agents, or representatives. Note that these Terms and
access to the Website do not give you any rights, title or interest in or to any intellectual property rights,
except for the limited access rights expressed in the Usage Restrictions section below. DeltaClaims and its
affiliates, related companies, suppliers, vendors, retailers, agents, and representatives reserve all rights not
granted in these Terms.</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6 font-bold">Usage Restrictions</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6">DeltaClaims grants you a non-transferable, non-exclusive, revocable, limited license to use and access the
Website solely for your own personal, non-commercial use in accordance and full compliance with all terms,
conditions, and restrictions of usage set forth herein.</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6">The rights granted to you in these Terms are subject, without limitation and in addition to all other terms,
conditions, and restrictions set forth in these Terms, to the following restrictions: (a) you shall not sell, rent,
lease, transfer, assign, distribute, host, or otherwise commercially exploit any part of the Website or of any
other products, features, apps, services, technologies, or software offered by DeltaClaims in connection
therewith; (b) you shall not change, make derivative works of, disassemble, reverse compile or reverse
engineer any part of the Website or of any other products, features, apps, services, technologies, code, or
software offered by DeltaClaims in connection therewith; (c) you shall not use information about the Website
or about any other products, features, apps, services, technologies, or software offered by DeltaClaims in
connection therewith obtained while using or accessing the Website to, directly or indirectly, build, conceive
of, fund, manage, own, develop, or create a similar or competitive website, product, feature, application, 
service, technology, program, platform, or software; and (d) except as expressly stated herein, no part of
the Website or of any other products, features, apps, services, technologies, programs, platforms, or
software offered by DeltaClaims in connection therewith, may be copied, reproduced, distributed,
republished, downloaded, displayed, posted or transmitted in any form or by any means unless otherwise
indicated. Any future release, update, or other addition to functionality of the Website shall be subject to
these Terms. All copyright and other proprietary notices on the Website must be retained on all copies
thereof.</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6">DeltaClaims reserves the right to change, suspend, or cease operating the Website with or without notice
to you. You acknowledge and agree that DeltaClaims shall not be held liable to you or any third-party for
any change, interruption, disruption, or termination of the Website or any part thereof.</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6">In addition to the foregoing, you agree not to use the Website or any other products, features, apps,
services, technologies, programs, platforms, or software offered by DeltaClaims in connection therewith to
collect, upload, transmit, display, or distribute any User Content (i) that infringes or violates any third-party
right including, without limitation, any intellectual property or proprietary right; (ii) that is unlawful, fraudulent,
discriminatory, harassing, abusive, tortious, threatening, harmful, invasive of another's privacy, vulgar,
defamatory, false, misleading, trade libelous, pornographic, obscene, patently offensive, or that promotes
racism, bigotry, hatred, or physical harm of any kind against any group or individual; (iii) that is harmful to
minors in any way; or (iv) that is in actual or attempted violation of any law, statute, regulation, or obligation
or restriction imposed by any third party.</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6">You further agree not to: (i) upload, transmit, or distribute to or through the Website any software, malware,
virus, or malicious code intended to damage or alter a computer system or data, or which could disable,
overburden, or impair the proper working or appearance of the Website or any part thereof; (ii) send through
the Website unsolicited or unauthorized advertising, promotional materials, junk mail, spam, chain letters,
pyramid schemes, or any other form of duplicative or unsolicited messages; (iii) use the Website to harvest,
collect, gather or assemble information or data, through automated means or otherwise, regarding other
users without their prior written and informed consent and without the prior written and informed consent of
DeltaClaims; (iv) interfere with, disrupt, or create an undue burden on servers or networks connected to the
Website, or violate the regulations, policies or procedures of such networks; (v) attempt to gain
unauthorized access to the Website or any part thereof, whether through password mining or any other
means; (vi) harass or interfere with any other user's use and enjoyment of the Website; or (vi) use software
or automated agents or scripts to produce multiple accounts on the Website, or to generate automated
searches, requests, messages, or queries to the Website. You must obtain our prior written permission to
modify, create derivative works of, decompile, or otherwise attempt to extract source code from the Website,
from any other products, features, apps, services, technologies, and software offered by DeltaClaims, or
from DeltaClaims, generally.</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6">We reserve the right to review any User Content, and to investigate and/or take appropriate action against
you in our sole discretion if you seriously or repeatedly violate these Terms or if doing so may expose us
or others to legal liability; harm our community of users; compromise or interfere with the integrity or
operation of any of our services, systems or products; where we are restricted due to technical limitations;
or where we are prohibited from doing so for legal reasons. Such action may include removing or modifying
your User Content, terminating your account, and/or reporting you to law enforcement authorities.
If you provide DeltaClaims with any feedback or suggestions regarding the Website, you hereby assign to
DeltaClaims all rights in such Feedback and agree that DeltaClaims shall have the exclusive right to use,
develop, transfer, assign, and fully exploit such Feedback and related information in any manner and for
any reason. DeltaClaims will treat any Feedback you provide to DeltaClaims as non-confidential and non-
proprietary.</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6 font-bold">Links To Other Web Sites</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6">The Website may contain links, or redirect users, to third party web sites or services that are not owned or
controlled by DeltaClaims. DeltaClaims has no control over, and assumes no responsibility for the content,
privacy policies, goods/services, or practices of any third-party web sites or services. We do not warrant
the offerings of any of these entities/individuals or their websites.</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6">You acknowledge and agree that DeltaClaims shall not be responsible or liable, directly or indirectly, for
any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such
content, goods or services available on or through any such third-party web sites or services.</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6">We strongly advise you to read the terms and privacy policies of any third-party web sites or services that
you visit.</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6 font-bold">Termination</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6">We may terminate or suspend your account and bar access to the Website immediately, without prior notice
or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not
limited to a breach of these Terms. If you wish to terminate your account, you may simply discontinue using
the Website.</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6">All provisions of the Terms which by their nature should survive termination shall survive termination,
including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of
liability.</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6 font-bold">Indemnification</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6">You agree to indemnify and hold DeltaClaims and its officers, employees, and agents harmless, including
costs and attorneys' fees, from any claims, demands, damages, obligations, losses, liabilities, costs or debt,
and expenses made by any third-party due to or arising out of (a) your use of the Website, (b) your violation
of these Terms or of any other applicable terms, guidelines, policies, and rules governing your use or access
to the Website or any part thereof, (c) your violation of applicable laws or regulations or (d) your User
Content. DeltaClaims reserves the right to assume the exclusive defense and control of any matter for
which you are required to indemnify us, including choosing legal counsel of DeltaClaims' choice, and you
agree to cooperate with our defense of these claims. You agree not to settle any matter without the prior
written consent of DeltaClaims. DeltaClaims will use reasonable efforts to notify you of any such claim,
action or proceeding upon becoming aware of it.</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6 font-bold">Limitation of Liability</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6">In no event shall DeltaClaims, nor its directors, employees, partners, agents, suppliers, or affiliates, be
liable for any indirect, incidental, special, consequential, exemplary, or punitive damages, including without
limitation, loss of profits, income, data, opportunity, use, goodwill, or other intangible losses, resulting from
(i) your access to or use of or inability to access or use the Website; (ii) any conduct or content of any third
party on the Website; (iii) any content obtained from the Website; and (iv) unauthorized access, use or
alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence)
or any other legal theory, whether or not we have been informed of the possibility of such damage, and
even if a remedy set forth herein is found to have failed of its essential purpose.</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6">Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability
for consequential or incidental damages, so the limitations above may not apply to you.</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6">You agree to comply with all applicable local, state, national, and foreign laws, rules, and regulations,
including, but not limited to, all applicable import and export laws and regulations governing use,
transmission and/or communication of content, in connection with their performance, participation, access
and/or use of the Website.</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6 font-bold">Disclaimer</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6">Your use of the Website is at your sole risk. The Website is provided on an “AS IS” and “AS AVAILABLE”
basis. The Website is provided without warranties of any kind, whether express or implied, including, but
not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or
course of performance. The Website may contain bugs, errors, and other problems. YOU ASSUME ALL
RISKS AND ALL COSTS ASSOCIATED WITH YOUR USE OF THE WEBSITE, INCLUDING, WITHOUT
LIMITATION, ANY INTERNET ACCESS FEES, BACK-UP EXPENSES, COSTS INCURRED FOR THE
USE OF YOUR DEVICE AND PERIPHERALS, AND ANY DAMAGE TO ANY EQUIPMENT, SOFTWARE,
INFORMATION OR DATA. In addition, we are not obligated to provide any maintenance, technical, or other
support for the Website. DeltaClaims reserves the right to offer the Website or any other products, features,
apps, services, technologies, programs, platforms, or software for free or charge fees to access and use
some or all of the foregoing.</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6">DeltaClaims, its subsidiaries, affiliates, and its licensors do not warrant that (a) the Website will function
uninterrupted, error-free, defect-free, secure or available at any particular time or location; (b) any errors or
defects will be corrected; (c) the Website is free of viruses or other harmful components; or (d) the results of
using the Website will meet your needs or requirements.</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6">The information provided on the Website is for general reference only and is not intended to replace,
substitute, render, or provide professional legal advice; you should always consult a lawyer to determine
your legal rights. The Website is not operated by a law firm and the content provided by this Website should
not be used as a substitute for legal advice from a qualified lawyer. You should never rely on the accuracy
or applicability of the information provided by, or through, this Website. We strongly recommend you consult
a lawyer to discuss the laws in your area and how they apply to your particular factual circumstances. Any
form of use of the Website, and the services offered thereunder, by a subscribing member or potential client
is not intended to and will not create an attorney-client relationship between any person or entity and
DeltaClaims. Any electronic communication sent to us will not create an attorney-client relationship between
the user and DeltaClaims, such being expressly denied.</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6 font-bold">Governing Law</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6">This Agreement is governed by and construed in accordance with the internal laws of the State of Florida
without giving effect to any choice or conflict of law provision or rule that would require or permit the
application of the laws of any jurisdiction other than those of the State of Florida. Venue shall exclusively
lie in the state or Federal courts located within the State of Florida and sitting in Broward County. In the
event litigation is necessary to enforce any provision of this Agreement or to prosecute a perceived breach
of this Agreement, the prevailing Party shall be entitled to an award of all costs, expenses, and attorneys'
fees, whether taxable or not, to be paid by the non-prevailing Party.</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6">Our failure to enforce any right or provision under, or in connection with, these Terms will not be considered
a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court,
the remaining provisions of these Terms shall remain in effect.</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6 font-bold">Changes</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6">These Terms were last updated on October 1, 2024. DeltaClaims reserves the right to update these Terms
at any time. A notice of any material change, stating “Our Terms of Use have changed. Please review the
highlighted change(s),” will be transmitted to all users or posted on the Website home page for thirty (30)
days prior to the implementation of such change. There are two boxes at the end of DeltaClaims' notice of
change (1) an "I accept" box, and (2) an "I do not accept" box. If user does not mark the "I do not accept"
box user will be deemed to have accepted DeltaClaims' Terms of Use updates. By continuing to access or
use the Website after any revisions become effective, you agree to be bound by the revised terms. If you
do not agree to the new terms, you are no longer authorized to use the Website.</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6 font-bold">Contact Us</p>
<p></p>
<p className="text-left text-md lg:text-lg mt-6">If you have any questions about these Terms, please contact DeltaClaims by mail at the following address:</p>
<p className="text-center text-md lg:text-lg mt-6">Deltaclaims.AI, LLC</p>
<p className="text-center text-md lg:text-lg mt-6">8751 W Broward Blvd Suite 408,</p>
<p className="text-center text-md lg:text-lg mt-6">Plantation, Florida 33324</p>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;
