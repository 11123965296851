import React from "react";
import { useLocation } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const BlogDetail = () => {
    const location = useLocation();
    const { blog } = location.state;

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
        className: "blog-slider",
        customPaging: () => <div className="custom-dot"></div>
    };

    return (
        <div className="w-full bg-[#f3fff4] pb-[200px] relative mt-[20vh]">
            <div className="w-full p-4 max-w-screen-xl mx-auto">
                <h1 className="text-2xl lg:text-[60px] font-['Montserrat'] leading-[70px] text-black mb-8">{blog.title}</h1>

                {blog.images.length <= 5 ? (
                    <div className="flex flex-wrap gap-4 mb-8">
                        {blog.images.map((image, index) => (
                            <img
                                key={index}
                                src={image}
                                alt={`blog-${index}`}
                                className="w-full sm:w-[calc(50%-1rem)] lg:w-[calc(33.33%-1rem)] rounded-lg object-cover"
                                style={{ maxHeight: "400px" }}
                            />
                        ))}
                    </div>
                ) : (
                    <div className="mb-8">
                        <Slider {...settings}>
                            {blog.images.map((image, index) => (
                                <div key={index} className="px-2">
                                    <img src={image} alt={`blog-${index}`} className="w-full rounded-lg object-cover mx-auto" style={{ height: "400px" }} />
                                </div>
                            ))}
                        </Slider>
                    </div>
                )}

                <p className="text-base font-medium font-['Montserrat'] my-6">{blog.content}</p>
            </div>

            <style jsx>{`
                .blog-slider .slick-dots li button:before {
                    color: #59bb66;
                }
                .blog-slider .slick-dots li.slick-active button:before {
                    color: #0195d8;
                }
                .blog-slider .slick-prev:before,
                .blog-slider .slick-next:before {
                    color: #59bb66;
                }
            `}</style>
        </div>
    );
};

export default BlogDetail;
