import React from "react";
import Button from "../../components/ui/button";
import GradientText from "../../components/ui/gradient-text";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import { Padding } from "@mui/icons-material";

const OurSolution = () => {
    const sequence = [
        // {
        //   icon: "/assets/images/our-solution/icon1.png",
        //   number: "/assets/images/our-solution/1.png",
        //   text: "Create an account",
        //   list: [
        //     "Choose your plan",
        //     "Pay for your plan",
        //     "Welcome email with your login information and training materials",
        //     "24x7 by clicking Get Access",
        //   ],
        // },
        // {
        //   icon: "/assets/images/our-solution/icon2.png",
        //   number: "/assets/images/our-solution/2.png",
        //   text: "Login",
        //   list: [
        //     "Enforces two-factor authentication and step-up authentication",
        //     "Your customer’s claim information is important and so we take the security of this information seriously...",
        //   ],
        // },
        {
            icon: "/assets/images/our-solution/icon3.png",
            number: "/assets/images/our-solution/1.png",
            text: "Create Claim",
            list: ["Each claim is assigned a unique number and segmented from other claims in the system."]
        },
        {
            icon: "/assets/images/our-solution/icon4.png",
            number: "/assets/images/our-solution/2.png",
            text: "Upload Files",
            list: ["Our AI engine will classify and organize files based on its contents.", "Explore key connections between file details."]
        },
        {
            icon: "/assets/images/our-solution/icon8.png",
            number: "/assets/images/our-solution/3.png",
            text: "Retrieve Information",
            list: ["Ask the AI bot anything about a claim.", "Visualize differences in estimates.", "See policy nuances simplified."]
        },
        {
            icon: "/assets/images/our-solution/icon6.png",
            number: "/assets/images/our-solution/4.png",
            text: "Apply Insights",
            list: ["Let AI gather details for correspondence.", "Easily create summaries and reports.", "Access Knowledge Base & Support Portal 24/7."]
        }
        // {
        //   icon: "/assets/images/our-solution/icon7.png",
        //   number: "/assets/images/our-solution/7.png",
        //   text: "Create emails, letters, tasks, and calendar items from within the tool",
        //   list: ["Extracts due dates and deadlines from the claim files"],
        // },
        // {
        //   icon: "/assets/images/our-solution/icon8.png",
        //   number: "/assets/images/our-solution/8.png",
        //   text: "Our customized AI for the claims industry automatically creates summaries and reports",
        //   list: [
        //     "Claims summaries",
        //     "Organizes list of estimates",
        //     "Damage/Repair protocols",
        //     "Delta Reports and Analysis",
        //   ],
        // },
    ];

    const benefits = {
        items: [
            // {
            //   title: "Upload All Relevant Case Files ",
            //   description:
            //     "From multiple sources, upload Estimates, Policy, Reports, and other documents to pursue your claims.",
            //   // image: "/assets/images/our-solution/upload-files.jpg",
            // },
            {
                title: "Auto File Classification",
                description: "DeltaClaims.AI automatically categorizes and tags your files for easy retrieval and organization.",
                video: "/assets/images/our-solution/DocumentDemo.mp4",
                poster: "/assets/images/home/docs3.png"
            },
            {
                title: "Policy Analysis and Summary",
                description: "High-speed, high-quality analysis and description of key insurance policy details.",
                video: "/assets/images/our-solution/PolicyDemo.mp4",
                poster: "/assets/images/home/policy-page.png"
            },
            {
                title: "DeltaMate® Estimate Comparison and Analysis",
                description: "Quickly compare any two estimates side by side with detailed analysis of items and their costs.",
                video: "/assets/images/our-solution/DM-Demo.mp4",
                poster: "/assets/images/home/DM-1.png"
            },
            {
                title: "Eureka - AI ChatBot Claims Assistant",
                description: "Ask detailed, case-specific questions and request information to be applied in meaningful ways.",
                video: "/assets/images/our-solution/ChatBotDemo.mp4",
                poster: "/assets/images/our-solution/chatbot-page.jpg"
            }
        ]
    };

    useEffect(() => {
        const messages = ["Augment your workflows.", "Demand Quality Insights.", "Enhance decisions, faster.", "Maximize your claims capacity."];
        const textElement = document.getElementById("animated-text");
        let index = 0;

        function changeText() {
            if (textElement) {
                textElement.textContent = messages[index];
                index = (index + 1) % messages.length;
            }
        }

        changeText();
        const interval = setInterval(changeText, 3000);

        return () => clearInterval(interval); // Cleanup on unmount
    }, []);
    return (
        <div className="w-full relative bg-[#f3fff4]">
            <div className="w-full relative">
                <img
                    alt="banner"
                    src="/assets/images/team/team-shape-banner.png"
                    className="w-full absolute -bottom-[55px] lg:bottom-0 lg:relative z-10 lg:h-[750px]"
                />
                <div className="w-full p-4 bg-[#001235] lg:bg-transparent lg:absolute top-0 left-0 right-0 mx-auto h-full z-10">
                    <img alt="banner" src="/assets/images/home/home-banner-shadow.png" className="absolute left-0 top-0 hidden xl:block" />
                    <div className="w-full max-w-screen-xl mx-auto flex items-center flex-wrap md:flex-nowrap h-full justify-left lg:justify-between relative z-10">
                        <div className="w-full lg:w-5/12 text-white text-center lg:text-left relative z-10">
                            <h1 className="text-2xl lg:text-[60px] font-['Montserrat'] capitalize lg:leading-[70px] mb-8">
                                Our Product Suite
                                <span className="font relative ml-3">
                                    {/* <img
                    src="/assets/images/home/line-through.png"
                    alt="sol"
                    className="absolute left-0 top-0 w-full h-full z-[-1]"
                  /> */}
                                </span>
                            </h1>
                            <Button
                                fill={true}
                                text={"Explore"}
                                type={"button"}
                                icon={true}
                                onClick={() => {
                                    const element = document.getElementById("features-section");
                                    if (element) {
                                        element.scrollIntoView({ behavior: "smooth" });
                                    }
                                }}
                            />
                        </div>
                        <div className="w-full flex items-center text-center lg:text-right text-3x2 lg:text-[50px] z-10 text-white font-['Montserrat'] pb-16 mb-4 lg:ml-72 lg:pl-24 pt-2">
                            <div className="heroSection">
                                <div className="textContainer">
                                    <h2 id="animated-text" className="animatedText" aria-live="polite">
                                        Augment your workflows.
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="w-full relative z-10 lg:w-6/12 pt-4 lg:pt-[40px] pr-10">
                            {/* <img alt="banner" src="/assets/images/team/team-banner.png" /> */}
                            {/* <video width="1800" height="1000" style={{ borderRadius: "15px" }} autoPlay loop muted>
                <source src="/assets/images/home/quick-demo.mp4" type="video/mp4" />
              </video> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full pt-[50px] relative">
                {/* <img
          src="/assets/images/our-solution/wave-right.png"
          alt="wave-right"
          className="absolute -top-[200px] right-0"
        /> */}
                <img src="/assets/images/our-solution/half-circle.png" alt="wave-right" className="absolute top-[200px] right-0" />
                {/* <img
          src="/assets/images/our-solution/left-wave.png"
          alt="left-wave"
          className="absolute top-[50%] left-0"
        /> */}
                <div className="w-full max-w-screen-xl mx-auto relative z-10 p-4 xl:p-0">
                    <div className="w-full flex items-center justify-between flex-wrap lg:flex-nowrap">
                        <div className="text-center lg:text-left w-full">
                            <div id="features-section" className="w-full flex items-center justify-center lg:justify-start gap-2">
                                {/* <img src="/assets/images/home/color-favi.png" alt="favi" /> */}
                                <GradientText
                                    classes={"uppercase text-center font-medium text-xl lg:text-[22px]"}
                                    // text={"deltaclaims.ai"}
                                />
                            </div>
                            <h2 className="text-2xl lg:text-[50px] text-black font-['Montserrat'] lg:leading-[55px] pb-10 py-3">
                                At the
                                <span className="font-bold ml-3">Core</span>:
                            </h2>
                            <p className="font-['Montserrat'] text-base">{/* Seamless claims management: */}</p>
                        </div>
                        <div className="flex flex-wrap xl:flex-nowrap items-center gap-4 w-full justify-center lg:justify-end mt-4 lg:mt-0 mb-2 lg:mb-0">
                            <div
                                style={{
                                    background: "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                    background: "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                    background: "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                    background: "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)"
                                }}
                                className="p-0.5 bg-gradient-to-r from-[#59bb66] to-[#0195d8] w-[250px] h-[49px] rounded-full flex-shrink-0 mx-auto lg:mx-0">
                                <NavLink to="/what-is-ai">
                                    <button className="w-full bg-[#f3fff4] items-center focus:outline-none p-2 rounded-full text-white w-full h-full text-base font-['Poppins'] font-medium capitalize transition-all duration-300 flex-shrink-0 flex items-center justify-center gap-x-2">
                                        <img src="/assets/images/home/color-favi.png" alt="img" />
                                        <GradientText
                                            classes={"tracking-normal normal-case text-base font-['Poppins']"}
                                            text={"Why AI?"}
                                            onClick={() => (window.location.href = "https://deltaclaims.ai/what-is-ai")}
                                        />
                                    </button>
                                </NavLink>
                            </div>
                            {/* <Button
                type={"button"}
                text={"Sign Up"}
                fill={true}
                icon={true}
                onClick={() =>
                    (window.location.href =
                      "https://deltaclaims.ai/login-signup")
                  }
              /> */}
                        </div>
                    </div>
                    <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full gap-[24px] pb-[30px] relative">
                        {/* <img
              src="/assets/images/our-solution/arrows.png"
              alt="arrows"
              className="hidden 2xl:block absolute top-[200px] -right-[55px]"
            /> */}
                        {sequence.map((data, index) => {
                            return (
                                <div
                                    style={{
                                        background: "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                        background: "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                        background: "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                        background: "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)"
                                    }}
                                    key={index}
                                    className="p-0.5 bg-gradient-to-r from-[#59bb66] to-[#0195d8] w-full rounded-2xl">
                                    <div className="w-full bg-white rounded-2xl h-full p-[25px] text-left">
                                        <div className="flex justify-between">
                                            <img className="w-[70px] h-[70px] object-contain" src={data.icon} alt={data.icon} />
                                            <img className="h-[90px] object-contain" src={data.number} alt={data.number} />
                                        </div>
                                        <p className="font-semibold font-['Montserrat'] text-[20px] mt-6 mb-4">{data.text}</p>
                                        {data.list.length > 0 &&
                                            data.list.map((li, liIndex) => {
                                                return (
                                                    <div key={liIndex} className="flex gap-3 mb-2">
                                                        <div
                                                            style={{
                                                                background: "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                                                background: "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                                                background: "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                                                background: "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)"
                                                            }}
                                                            className="h-2 w-2 rounded-full bg-gradient-to-r from-[#59bb66] to-[#0195d8] flex-shrink-0 mt-2"></div>
                                                        <p className="text-base font-['Montserrat']">{li}</p>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="w-full pt-[120px] pb-[40px]">
                        <h2 className="text-2xl lg:text-[70px] text-black font-['Montserrat'] leading-[55px] pb-8">
                            <span className="font ml-3 pb-8">Features</span>
                        </h2>
                        <div className="w-full flex flex-col gap-8">
                            {benefits.items.map((item, index) => (
                                <div key={index} className="flex flex-col lg:flex-row gap-6">
                                    <div className="w-full lg:w-[30%] flex items-center">
                                        <div>
                                            <h3 className="text-xl font-['Montserrat'] font-semibold mb-3 text-center justify-center">{item.title}</h3>
                                            <p className="font-['Montserrat'] text-base text-center justify-center">{item.description}</p>
                                        </div>
                                    </div>
                                    <div className="w-full lg:w-[70%]">
                                        <div
                                            className="p-0.5 bg-[#f6fef4] rounded-2xl w-full"
                                            style={{
                                                background: "bg-[#f6fef4]"
                                            }}>
                                            {/* <div className="w-full bg-white rounded-2xl min-h-[400px] flex items-center justify-center p-4"> */}
                                            <img
                                                src={item.image}
                                                // alt={item.title}
                                                className="w-full h-full object-cover"
                                            />
                                            <video width="1600" height="900" style={{ borderRadius: "20px" }} muted controls preload="metadata" poster={item.poster}>
                                                <source src={item.video} type="video/mp4" />
                                            </video>
                                            {/* </div> */}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* <div className="w-full pt-[120px] pb-[40px]">
            <div className="w-full text-center flex items-center justify-center gap-2 mb-2">
              <img src="/assets/images/home/color-favi.png" alt="favi" />
              <GradientText
                classes={
                  "uppercase text-center font-medium text-xl lg:text-[22px]"
                }
                text={"conferences"}
              />
            </div>
            <h2 className="text-2xl lg:text-[50px] text-black font-['Montserrat'] leading-[55px] pb-6">
              Join the Conversation
              <span className="font-bold ml-3"></span>
            </h2>
            {conferences.map((conference, index) => {
              const conferenceClass =
                conference.title === "WINDSTORM 2024"
                  ? "windstorm-conference"
                  : "";

              return (
                <div
                  style={{
                    background:
                      "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    background:
                      "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    background:
                      "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    background:
                      "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                  }}
                  key={index}
                  className={`p-0.5 bg-gradient-to-r from-[#59bb66] to-[#0195d8] w-full rounded-2xl mb-[20px] ${conferenceClass}`}
                >
                  <div className="w-full flex flex-wrap lg:flex-nowrap gap-[20px] justify-between bg-white rounded-2xl h-full p-4 lg:p-[30px] text-left">
                    <img
                      className="w-full lg:w-[300px] h-auto object-contain rounded-2xl"
                      src={conference.imgSrc}
                      alt={conference.title}
                    />
                    <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-[25px] xl:divide-x divide-[#e5e5e5]">
                      <div className="w-full lg:pl-[25px] lg:pt-[30px]">
                        <h3 className="font-bold text-[18px] font-['Montserrat'] mb-3">
                          {conference.title}
                        </h3>
                        <p className="text-base font-['Montserrat'] break-words">
                          {conference.description}
                        </p>
                      </div>
                      <div className="w-full lg:pl-[25px] lg:pt-[30px]">
                        <h3 className="font-bold text-[18px] font-['Montserrat'] mb-3">
                          Location
                        </h3>
                        <div className="flex gap-2 items-center mb-2">
                          <p className="text-base font-['Montserrat']">
                            {conference.location}
                          </p>
                        </div>
                      </div>
                      <div className="w-full lg:pl-[25px] lg:pt-[30px]">
                        <h3 className="font-bold text-[18px] font-['Montserrat'] mb-3">
                          Conference Info
                        </h3>
                        <div className="flex gap-2 items-center mb-2">
                          <p className="text-base font-['Montserrat']">
                            {conference.info.date}
                          </p>
                        </div>
                        <div className="flex gap-2 items-center mb-2">
                          <p className="text-base font-['Montserrat'] break-words">
                            <a
                              href={conference.info.website}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-500 underline"
                            >
                              {conference.info.website}
                            </a>
                          </p>
                        </div>
                        <div className="flex gap-2 items-center mb-2">
                          <p className="text-base font-semibold font-['Montserrat']">
                            Live Demos:
                          </p>
                          <p className="text-base font-['Montserrat']">
                            {conference.info.demos}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div> */}
                    <div className="w-full pb-[100px] flex justify-center">
                        <Button
                            type={"button"}
                            text={"get access"}
                            fill={true}
                            icon={true}
                            onClick={() => (window.location.href = "https://deltaclaims.ai/login-signup")}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurSolution;
